import React from 'react';
import FictionalIcon from '../../public/images/fictional_icon.svg';

type CustomMapMarkerProps = {
  feature: {
    geometry: {
      coordinates: [number, number];
      type: string;
    },
    properties: {
      comment: string;
      place: string;
      placeType: number;
      username: string;
    };
  };
  markerColor: string,
  totalUniqueUsers: number
};
export default function CustomMapMarker({ feature, totalUniqueUsers, markerColor }: CustomMapMarkerProps) {
  const isFictionalPlace = feature?.properties?.placeType === 11;
  const usernameInitial = feature?.properties?.username[0];

  return (
    <div className='relative'>
      {totalUniqueUsers > 1 && <div className='absolute -top-1 -right-2 bg-white text-grey-900 text-[10px] font-[Sans-Serif] rounded-full size-4 flex items-center justify-center shadow-md'>
        {totalUniqueUsers}<span className='text-[8px]'>+</span>
      </div>}
      {isFictionalPlace ? (
        <div className="relative flex items-center justify-center">
          <div
            style={{
              backgroundColor: '#FF0064',
              opacity: 0.4,
            }}
            className="absolute size-8 rounded-full -z-10"
          />
          <FictionalIcon />
        </div>
      ) : (
        <div className="relative flex items-center justify-center">
          <div
            style={{
              backgroundColor: markerColor || '#FF0064',
              opacity: 0.4,
            }}
            className="absolute size-8 rounded-full -z-10"
          />
          <div
            style={{
              backgroundColor: markerColor || '#FF0064',
            }}
            className="text-white font-[Sans-Serif] font-semibold rounded-full size-6 flex items-center justify-center"
          >
            {usernameInitial}
          </div>
        </div>
      )}
    </div>
  );
}

import { useSpring, animated } from 'react-spring';
import React, { useMemo } from 'react';
import Avatar from '@atoms/Avatar';
import lodash from 'lodash';

export const getFontColor = (backgroundColor = '#000000') => {
  if (backgroundColor.charAt(0) === '#') {
    backgroundColor = backgroundColor.slice(1);
  }

  const red = parseInt(backgroundColor.substr(0, 2), 16);
  const green = parseInt(backgroundColor.substr(2, 2), 16);
  const blue = parseInt(backgroundColor.substr(4, 2), 16);

  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  if (brightness > 125) {
    return '#000000';
  }

  return '#FFFFFF';
};

export const getRandomColor = (currentRosFeature) => {
  const colors = currentRosFeature?.colors || currentRosFeature?.color;
  const isTetrisInpiredColors = !colors?.multipleColors;

  let TETRIS_COLORS = ['#B99AC7', '#81B1FA', '#B1D878', '#FF5497', '#FFE955', '#F26A60'];

  const bgColor1 = colors?.talkingTileBgColor1 || '#B99AC7';
  const bgColor2 = colors?.talkingTileBgColor2 || '#81B1FA';
  const bgColor3 = colors?.talkingTileBgColor3 || '#B1D878';
  if (isTetrisInpiredColors) {
    TETRIS_COLORS = [bgColor1, bgColor2, bgColor3];
  }
  return lodash.shuffle(TETRIS_COLORS)[0];
};

const Tile = ({ tile, entryProps, currentRosFeature, onPress }) => {
  if (!currentRosFeature || !tile) return null;

  const showUsername = currentRosFeature?.setting?.showUserName;

  const isVerticalLayout = currentRosFeature?.setting?.layoutMode === 'square';
  const isSandbox = currentRosFeature?.setting?.isSandbox;
  const fontFamily = currentRosFeature?.setting?.FontStyle || 'inter';

  const { truncatedText, truncatedUsername } = useMemo(() => {
    const textLimit = isVerticalLayout ? 150 : isSandbox ? 100 : 300;
    const maxUsernameLength = 30;

    return {
      truncatedText: tile.words.length > textLimit ?
        tile.words.slice(0, textLimit) + '...' :
        tile.words,
      truncatedUsername: tile.username.length > maxUsernameLength ?
        tile.username.slice(0, maxUsernameLength) + '...' :
        tile.username
    };
  }, [tile.words, tile.username, isVerticalLayout]);

  const TILE_GAP = 20;
  const LEFT_OFFSET = 10;
  const LEFT = tile.column * (tile.width + TILE_GAP) + LEFT_OFFSET;

  const styleProps = entryProps || {
    backgroundColor: tile.backgroundColor,
    top: tile.top || 0
  };
  return <animated.div
    onClick={onPress}
    style={{
      ...styleProps,
      position: 'absolute',
      left: `${LEFT}px`,
      width: `${tile.width}px`,
      height: `${tile.height}px`,
      padding: '0px 24px 24px 24px',
      borderRadius: '16px',
      boxShadow: tile.isFadingOut
        ? '0px 10px 0px rgba(255, 255, 255, 0.2)'
        : `0px 10px 0px ${darkenColor(tile.backgroundColor, 20)}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      transition: tile.isNew ? 'none' : 'top 0.1s linear, box-shadow 0.1s ease-in-out',
      overflow: 'hidden',
      fontSize: isSandbox ? '16px' : tile.fontSize,
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        overflow: 'hidden',
        paddingTop: '24px',
        paddingBottom: '24px',
      }}
    >
      <p
        style={{
          color: tile.fontColor || '#212121',
          margin: 0,
          lineHeight: '1.3',
          fontSize: isSandbox ? '20px' : (tile.fontSize || '24px'),
          wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          fontFamily,
          textAlign: 'start',
          width: '100%',
        }}
      >
        {truncatedText}
      </p>
    </div>
    {tile.username && showUsername && (
      <div className='flex items-center gap-2'>
        <Avatar isProfile staticColor='bg-grey-500' name={truncatedUsername} />
        <p
          className={`text-grey-900 text-sm font-${fontFamily}`}
          style={{
            fontSize: tile.userNameFontSize,
            color: tile.fontColor || '#212121'
          }}
        >
          {truncatedUsername}
        </p>
      </div>
    )}
  </animated.div>;
};

class TileManager {
  constructor(container, currentRosFeature, chromaColor, textColor = '#111111') {
    this.container = container;
    this.tiles = [];
    this.columns = Array.from({ length: 3 }, () => []);
    this.onTilesUpdated = () => {};
    this.currentRosFeature = currentRosFeature;
    this.currentRosFeatureId = currentRosFeature.id;
    this.chromaColor = chromaColor;
    this.textColor = textColor;
    this.commentQueue = [];
    this.lastTileColor = '';

    this.TILE_GAP = 30;
    this.FIXED_COLS = 3;
    this.INITIAL_OFFSET = 100;
    this.BOTTOM_PADDING = 20;
    this.BOTTOM_GAP = 1;
    this.MIN_TILE_HEIGHT = 144;

    this.containerHeight = container.getBoundingClientRect().height;
    this.containerWidth = container.getBoundingClientRect().width;
    this.maxTileHeight = this.containerHeight * 0.5;
    this.tileWidth = this.calculateTileWidth();
    this.numCols = this.FIXED_COLS;
    this.lastTileId = 0;
    this.DROP_SPEED = this.calculateDropSpeed();

    this.animationFrame = null;
    this.measureTextHeight = null;
    this.isRemovingTiles = false;
    this.visibilityChangeSet = false;
    this.hasMovingTiles = false;
    this.worker = null;
  }

  getCurrentRosFeatureId() {
    return this.currentRosFeatureId;
  }

  setCurrentRosFeatureId(currentRosFeatureId) {
    this.currentRosFeatureId = currentRosFeatureId;
  }

  calculateDropSpeed() {
    return 1;
  }

  calculateTileWidth() {
    const isVerticalLayout = this.currentRosFeature?.setting?.layoutMode === 'square';
    const isSandbox = this.currentRosFeature?.setting?.isSandbox;
    let SIZE = 1920;

    if (isSandbox || isVerticalLayout) {
      SIZE = 1080;
    }

    const width = SIZE * (isVerticalLayout ? 0.75 : 0.85);
    return width / 3;
  }

  setContainer(container) {
    this.container = container;
    this.containerWidth = container.getBoundingClientRect().width;
    this.tileWidth = this.calculateTileWidth();
  }

  setContainerHeight(containerHeight) {
    this.containerHeight = containerHeight;
    this.containerWidth = this.container.getBoundingClientRect().width;
    this.maxTileHeight = this.containerHeight * 0.5;
    this.tileWidth = this.calculateTileWidth();
    this.DROP_SPEED = this.calculateDropSpeed();
  }

  getNumCols() {
    return this.numCols;
  }

  setCurrentRosFeature(feature) {
    this.currentRosFeature = feature;
    this.chromaColor = feature?.color?.talkingTilesBgColor;
    this.updateExistingTiles();
    this.numCols = this.FIXED_COLS;
  }

  updateExistingTiles() {
    this.textColor = !this.currentRosFeature?.color?.multipleColors ?
      this.currentRosFeature?.color?.talkingTilesTextColor :
      '#111';

    this.tiles.forEach(tile => {
      tile.backgroundColor = getRandomColor(this.currentRosFeature);
      tile.fontColor = this.textColor;
    });

    this.onTilesUpdated([...this.tiles]);
  }

  createTile(comment, index, props = {}) {
    const words = comment.words || comment;
    const username = comment.username || '';
    const { height: tempTileHeight, fontSize, userNameFontSize } = this.createTempTile(words, username);
    const backgroundColor = getRandomColor(this.currentRosFeature);

    const tile = {
      id: this.lastTileId,
      comment_id: comment?.comment_id || '',
      words,
      username,
      backgroundColor,
      fontColor: this.textColor,
      column: index % this.FIXED_COLS,
      top: -tempTileHeight * 10,
      targetTop: -tempTileHeight * 10,
      width: this.tileWidth,
      height: tempTileHeight * 1.25,
      visible: true,
      settled: false,
      isNew: true,
      fontSize,
      userNameFontSize,
      isBottom: false,
      ...props
    };

    this.lastTileId++;
    return tile;
  }

  dropTile({ comments, numCols = 3 }) {
    if (this.tiles.length > 10) {
      this.commentQueue.push(...comments);
      return;
    }

    const newTiles = comments.map((comment, index) =>
      this.createTile(comment, index, { isNew: true })
    );

    this.preCalculatePositions(newTiles);
    this.tiles = [...newTiles, ...this.tiles];
    this.reorganizeTiles();
    this.startAnimation();
  }

  preCalculatePositions(newTiles) {
    if (this.columns.length === 0) {
      this.columns = Array.from({ length: this.FIXED_COLS }, () => []);
    }

    const columnHeights = this.columns.map(column =>
      column.reduce((totalHeight, tile) => totalHeight + tile.height + this.TILE_GAP, 0)
    );

    const OFFSCREEN_BUFFER = 100;

    newTiles.forEach((tile, index) => {
      let minHeight = Infinity;
      let selectedColumn = 0;

      const numChecks = Math.min(3, this.FIXED_COLS);
      for (let i = 0; i < numChecks; i++) {
        const randomColumn = Math.floor(Math.random() * this.FIXED_COLS);
        if (columnHeights[randomColumn] < minHeight) {
          minHeight = columnHeights[randomColumn];
          selectedColumn = randomColumn;
        }
      }

      tile.column = selectedColumn;
      const currentColumn = this.columns[selectedColumn];

      let topmostTileTop = -Infinity;
      if (currentColumn.length > 0) {
        const sortedColumn = [...currentColumn].sort((a, b) => a.top - b.top);
        topmostTileTop = sortedColumn[0].top;
      }

      const respetiveBottomTileHeight = (currentColumn[0]?.height || tile.height) * 1.35;
      const topOffset = (OFFSCREEN_BUFFER + tile.height) + (index * respetiveBottomTileHeight / 3);

      if (currentColumn.length > 0 && topmostTileTop > -Infinity) {
        tile.top = Math.min(-(topOffset), topmostTileTop - tile.height - this.TILE_GAP * 5);
      } else {
        tile.top = -(topOffset);
      }

      columnHeights[selectedColumn] += tile.height + this.TILE_GAP;
      this.columns[selectedColumn].push(tile);
    });
  }

  reorganizeTiles(tiles = null) {
    const tilesToReorganize = tiles || this.tiles;
    this.numCols = 3;
    this.columns = Array.from({ length: this.numCols }, () => []);

    tilesToReorganize.forEach(tile => {
      const safeColumn = tile?.column >= this.numCols ?
        tile.column - 1 :
        tile?.column || 0;

      this.columns[safeColumn].push(tile);
    });

    this.columns.forEach(column => {
      column.sort((a, b) => a.top - b.top);

      if (column.length > 0) {
        column[column.length - 1].isBottom = true;
      }

      let accumulatedHeight = 0;
      for (let i = column.length - 1; i >= 0; i--) {
        const tile = column[i];
        if (tile.isNew) {
          tile.targetTop = this.containerHeight - (this.BOTTOM_PADDING + this.BOTTOM_GAP + accumulatedHeight + tile.height);
        }
        accumulatedHeight += tile.height + this.TILE_GAP;
      }
    });

    this.onTilesUpdated([...tilesToReorganize]);
  }

  startAnimation() {
    this.cancelAnimation();

    if (!this.worker) {
      const workerCode = `
        let animating = false;
        let lastTimestamp = 0;
        
        function animationLoop(timestamp) {
          if (!animating) return;
          
          if (timestamp - lastTimestamp >= 10) {
            lastTimestamp = timestamp;
            self.postMessage({ type: 'tick' });
          }
          
          requestAnimationFrame(animationLoop);
        }
        
        self.onmessage = function(e) {
          if (e.data.command === 'start') {
            if (!animating) {
              animating = true;
              requestAnimationFrame(animationLoop);
            }
          } else if (e.data.command === 'stop') {
            animating = false;
          }
        };
      `;

      const blob = new Blob([workerCode], { type: 'application/javascript' });
      this.worker = new Worker(URL.createObjectURL(blob));

      this.worker.onmessage = (e) => {
        if (e.data.type === 'tick') {
          this.animateTiles();
        }
      };
    }

    this.worker.postMessage({ command: 'start' });
  }

  animateTiles() {
    const containerHeight = this.currentRosFeature?.setting?.isSandbox ? 470 : 820;
    const halfContainerHeight = containerHeight * 0.7;
    let hasMovingTiles = false;

    this.columns.forEach(column => {
      let settledHeight = 0;
      let totalHeight = 0;

      for (let i = column.length - 1; i >= 0; i--) {
        const tile = column[i];
        totalHeight += tile.height + this.TILE_GAP;

        if (tile.settled) {
          settledHeight += tile.height + this.TILE_GAP;
        } else {
          hasMovingTiles = true;
          const previousTop = tile.top;
          const maxTop = containerHeight - totalHeight;
          const moveDistance = Math.min(this.DROP_SPEED, maxTop - tile.top);

          if (moveDistance > 0 && moveDistance < 0.1) {
            tile.top += Math.max(0.5, this.DROP_SPEED / 2);
          } else {
            tile.top += moveDistance;
          }

          if (tile.top >= maxTop) {
            tile.top = maxTop;
            tile.settled = true;
            settledHeight += tile.height + this.TILE_GAP;
          }

          tile.targetTop = tile.top;

          if (tile.top === previousTop && !tile.settled) {
            tile.top += this.DROP_SPEED;
            tile.targetTop = tile.top;
          }
        }
      }

      if (settledHeight > halfContainerHeight) {
        this.removeBottomTile();
      }
    });

    if (!hasMovingTiles) {
      if (this.worker) {
        this.worker.postMessage({ command: 'stop' });
      }
      this.tiles.forEach(tile => {
        tile.isNew = false;
      });
    }

    this.onTilesUpdated([...this.tiles]);
  }

  getRemovedCommentIds() {
    const saveStateKey = `talking-tiles-state_${this.currentRosFeature.id}`;
    const saveStateData = localStorage.getItem(saveStateKey);
    const saveStateObject = saveStateData ?
      JSON.parse(saveStateData) :
      { comments: [], removedCommentIds: [] };

    return saveStateObject.removedCommentIds;
  }

  async removeBottomTile() {
    if (this.isRemovingTiles) {
      return;
    }

    const allColumnsHaveSettledBottom = this.columns.every(column =>
      column.length > 0 && column.some(tile => tile.isBottom && tile.settled)
    );

    const removedCommentIds = [];

    if (allColumnsHaveSettledBottom) {
      this.isRemovingTiles = true;
      const tobeRemovedTiles = [];

      for (const column of this.columns) {
        if (column && column.length > 0) {
          const bottomTileIndex = column.findIndex(tile => tile.isBottom && tile.settled);

          if (bottomTileIndex !== -1) {
            const removedTile = column[bottomTileIndex];
            removedTile.isFadingOut = true;
            removedCommentIds.push(removedTile.comment_id);
            tobeRemovedTiles.push({
              tile: removedTile,
              columnIndex: this.columns.indexOf(column),
              index: bottomTileIndex
            });
          }
        }
      }

      if (tobeRemovedTiles.length < 3) {
        this.isRemovingTiles = false;
        return;
      }

      this.onTilesUpdated([...this.tiles]);

      await new Promise(resolve => setTimeout(resolve, 500));

      for (const { tile, columnIndex, index } of tobeRemovedTiles) {
        const column = this.columns[columnIndex];

        if (!column) return;

        this.tiles = this.tiles.filter(t => t.id !== tile.id);

        column.splice(index, 1);

        if (column.length > 0) {
          const newBottomTile = column[column.length - 1];
          newBottomTile.isBottom = true;
          newBottomTile.settled = false;

          let accumulatedHeight = this.BOTTOM_GAP;
          for (let i = column.length - 1; i >= 0; i--) {
            const currentTile = column[i];
            currentTile.targetTop = this.containerHeight - this.BOTTOM_PADDING - accumulatedHeight - currentTile.height;
            currentTile.settled = false;
            currentTile.isNew = false;
            accumulatedHeight += currentTile.height + this.TILE_GAP;
          }
        }
      }

      const saveStateKey = `talking-tiles-state_${this.currentRosFeature.id}`;
      const saveStateData = localStorage.getItem(saveStateKey);
      const saveStateObject = saveStateData ?
        JSON.parse(saveStateData) :
        { comments: [], removedCommentIds: [] };

      saveStateObject.removedCommentIds = [
        ...new Set([...saveStateObject?.removedCommentIds || [], ...removedCommentIds])
      ];

      localStorage.setItem(saveStateKey, JSON.stringify(saveStateObject));

      this.startAnimation();
      this.onTilesUpdated([...this.tiles]);

      setTimeout(() => {
        this.isRemovingTiles = false;
      }, 300);
    }
  }

  setOnTilesUpdated(callback) {
    this.onTilesUpdated = callback;
  }

  createTempTile(word, username) {
    const isSmallerLayout = this.currentRosFeature?.setting?.layoutMode === 'square' ||
                           this.currentRosFeature?.setting?.isSandbox;
    const maxCharacters = isSmallerLayout ? 200 : 300;
    const truncatedText = word.length > maxCharacters
      ? word.slice(0, maxCharacters) + '...'
      : word;

    const fontSize = isSmallerLayout ? '16px' : '24px';
    const userNameFontSize = isSmallerLayout ? '14px' : '16px';

    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.width = `${this.tileWidth}px`;
    tempDiv.style.paddingTop = '20px';
    tempDiv.style.paddingBottom = '20px';
    tempDiv.style.fontFamily = this.currentRosFeature?.setting?.FontStyle || 'inter';
    tempDiv.style.fontSize = fontSize;
    tempDiv.style.lineHeight = '1.3';
    tempDiv.innerText = truncatedText;

    this.container.appendChild(tempDiv);
    let height = tempDiv.scrollHeight;
    this.container.removeChild(tempDiv);

    let minHeight = this.MIN_TILE_HEIGHT;
    if (username && this.currentRosFeature?.setting?.showUserName) {
      minHeight += 20;
      height += 20;
    }

    height = Math.max(height, minHeight);
    return { height, fontSize, userNameFontSize };
  }

  clearTiles() {
    this.tiles = [];
    this.columns = [];
    this.cancelAnimation();
    this.commentQueue = [];
    this.onTilesUpdated([]);
  }

  cancelAnimation() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
      this.animationInterval = null;
    }
    if (this.worker) {
      this.worker.postMessage({ command: 'stop' });
    }
  }

  resizeContainer(container) {
    const scaleFactor = 1.25;
    this.container = container || this.container;
    const actualHeight = container.getBoundingClientRect().height;
    this.containerHeight = actualHeight / scaleFactor;
    this.containerWidth = container.getBoundingClientRect().width;
    this.maxTileHeight = this.containerHeight * 0.5;
    this.tileWidth = this.calculateTileWidth();
    this.DROP_SPEED = this.calculateDropSpeed();

    this.tiles.forEach(tile => {
      tile.width = this.tileWidth;
      tile.top = (tile.top / tile.height) * (this.containerHeight / scaleFactor);
      tile.targetTop = (tile.targetTop / tile.height) * (this.containerHeight / scaleFactor);
    });

    this.startAnimation();
  }

  setMeasureTextHeight(callback) {
    this.measureTextHeight = callback;
  }

  destroy() {
    this.clearTiles();
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
    if (this.visibilityChangeSet) {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
    }
  }

  getTilesState() {
    const visibleTiles = this.tiles.filter(tile => tile.top + tile.height > 0);
    return visibleTiles.map(tile => ({
      comment_id: tile.comment_id || '',
      ...tile,
      isNew: false,
      isSaved: true,
    }));
  }

  restoreTilesState(savedState) {
    this.tiles = [];
    this.columns = Array.from({ length: this.numCols }, () => []);

    this.tiles = savedState.map((savedTile, index) => {
      const tile = this.createTempTile(savedTile.words, savedTile.username);
      return {
        id: savedTile.id || this.lastTileId++,
        comment_id: savedTile.comment_id || '',
        words: savedTile.words,
        username: savedTile.username || '',
        backgroundColor: savedTile.backgroundColor || getRandomColor(this.currentRosFeature),
        fontColor: savedTile.fontColor || this.textColor,
        column: savedTile.column !== undefined ? savedTile.column : index % this.FIXED_COLS,
        top: savedTile.top,
        targetTop: savedTile.targetTop,
        width: this.tileWidth,
        height: tile.height * 1.25,
        visible: true,
        settled: savedTile.settled || false,
        isNew: false,
        isSaved: true,
        fontSize: savedTile.fontSize,
        userNameFontSize: savedTile.userNameFontSize,
        isBottom: savedTile.isBottom || false
      };
    });

    const maxId = Math.max(...this.tiles.map(tile => tile.id), 0);
    this.lastTileId = maxId + 1;

    this.tiles.forEach(tile => {
      if (this.columns[tile.column]) {
        this.columns[tile.column].push(tile);
      } else {
        const safeColumn = tile.column % this.numCols;
        tile.column = safeColumn;
        this.columns[safeColumn].push(tile);
      }
    });

    this.columns.forEach(column => {
      column.sort((a, b) => a.top - b.top);

      if (column.length > 0) {
        column[column.length - 1].isBottom = true;
      }
    });

    this.onTilesUpdated([...this.tiles]);
    this.startAnimation();
  }
}

const darkenColor = (color, percent) => {
  if (!color) return color;
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const r = (num >> 16) - amt;
  const g = ((num >> 8) & 0x00ff) - amt;
  const b = (num & 0x0000ff) - amt;
  return `rgb(${Math.max(r, 0)}, ${Math.max(g, 0)}, ${Math.max(b, 0)})`;
};

export const AnimatedTile = ({ tile, currentRosFeature, onPress }) => {
  const entryProps = useSpring({
    from: {
      top: tile.settled ? tile.targetTop : -tile.height,
      opacity: 1
    },
    to: {
      top: tile.top,
      backgroundColor: tile.backgroundColor,
      fontColor: tile.isFadingOut ? '#212121' : tile.fontColor,
      opacity: tile.isFadingOut ? 0.5 : 1,
    },
    config: {
      tension: 0,
      friction: 0,
      duration: tile.isFadingOut ? 100 : undefined,
    },
    immediate: !(tile.isNew) && !tile.isFadingOut,
  });

  return <Tile onPress={onPress} tile={tile} entryProps={entryProps} currentRosFeature={currentRosFeature} />;
};

export default TileManager;

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Skeleton, Tooltip } from '@nextui-org/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { tooltipContainerAnim } from '@atoms/new/tooltip';
import FictionalIcon from '../../../ui/public/images/fictional_icon.svg';
import { isZoomApp } from '@zoom-app/helpers';

const weatherIconMap = {
  '01d': '/images/weather-icons/sun-fill.svg',
  '01n': '/images/weather-icons/moon-stars-fill.svg',
  '02d': '/images/weather-icons/cloud-sun-fill.svg',
  '02n': '/images/weather-icons/cloud-moon-fill.svg',
  '03d': '/images/weather-icons/cloud-fill.svg',
  '03n': '/images/weather-icons/cloud-fill.svg',
  '04d': '/images/weather-icons/cloud-fill.svg',
  '04n': '/images/weather-icons/cloud-fill.svg',
  '09d': '/images/weather-icons/cloud-rain-fill.svg',
  '10d': '/images/weather-icons/cloud-snow-fill.svg',
  '11d': '/images/weather-icons/cloud-lightning-fill.svg',
  '13d': '/images/weather-icons/snowflake-fill.svg',
  '50d': '/images/weather-icons/cloud-fog-fill.svg',
};

function checkRegionForFahrenHeit() {
  // List of locales/regions where Fahrenheit is used
  const fahrenheitLocales = [
    'en-US', // United States
    'en-LR', // Liberia
    'en-BS', // Bahamas
    'en-BZ', // Belize
    'en-KY', // Cayman Islands
    'en-PW', // Palau
    'en-AS', // American Samoa
    'en-GU', // Guam
    'es-PR', // Puerto Rico (Spanish)
    'en-PR', // Puerto Rico (English)
    // 'en-GB' // UK (although the UK generally uses Celsius, Fahrenheit is sometimes used in media)
  ];

  const userLocale = navigator.language || 'en-US';

  return fahrenheitLocales.includes(userLocale);
}

function Commenters({ names }) {
  const profileColors = ['bg-red-200', 'bg-blue-200', 'bg-green-200', 'bg-yellow-200', 'bg-purple-200', 'bg-indigo-200', 'bg-pink-200', 'bg-cyan-200', 'bg-orange-200', 'bg-teal-200'];
  function getInitials(name) {
    return name.split(' ').map((n) => n[0]).join('');
  }
  return <div className='flex flex-col gap-2'>
    {names.map((name, index) => <div key={name} className='flex gap-2 items-center'>
      <div className={`${profileColors[index % profileColors.length]} size-6 rounded-full font-semibold text-[10px] text-grey-900 flex items-center justify-center`}>
        {getInitials(name)}
      </div>
      {name}
    </div>)}
  </div>;
}

export default function PlaceDetailsPopup({ place, onMouseEnter, onMouseOut, markerColor }) {
  const {
    placeType = '',
    placeName = '',
    username = '',
    otherCommenters = [],
    placeDetails = {}
  } = place;

  const [loc1, loc2] = placeName.split(',');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [temperature, setTemperature] = useState('');
  const [tempScale, setTempScale] = useLocalStorage('map::temp::scale', checkRegionForFahrenHeit() ? 'F' : 'C');
  const isFictionalPlace = placeType === 11;

  function convertTemperature(temp, scale) {
    if (scale === 'C') {
      setTemperature([(temp * (9 / 5)) + 32, 'F']);
      setTempScale('F');
    } else {
      setTemperature([(temp - 32) * (5 / 9), 'C']);
      setTempScale('C');
    }
  }

  useEffect(() => {
    if (tempScale === 'F') convertTemperature(placeDetails.weather.temp, 'C');
    else setTemperature([placeDetails.weather.temp, 'C']);
  }, []);

  const navigateSlide = (direction) => {
    setPhotoIndex(prev => prev + direction);
  };

  function getWeatherIcon(icon) {
    return weatherIconMap[icon] || `https://openweathermap.org/img/wn/${placeDetails.weather.icon}.png`;
  }

  return (
    <div className="w-[220px] flex flex-col select-none font-[Sans-Serif]" onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
      <div className='flex items-center gap-2 py-3'>
        {isFictionalPlace ? <FictionalIcon/> : <div
          style={{ backgroundColor: markerColor || '#FF0064' }}
          className='size-5 rounded-full font-semibold text-white flex items-center justify-center'>
          {username[0] || ''}
        </div>}
        <p className='flex gap-2 text-sm text-grey-900'>
          {username}
          {!!otherCommenters.length && <Tooltip
            showArrow
            initial="normal"
            whileHover="hover"
            animate="hover"
            variants={tooltipContainerAnim}
            delay={0}
            closeDelay={0}
            classNames={{
              base: ['before:bg-white dark:before:bg-white', 'max-w-64'],
              content: ['py-2 px-3 rounded-md text-xs', 'bg-white dark:bg-white text-grey-900'],
            }}
            content={<Commenters names={otherCommenters} />}>
            <div className='flex items-center bg-red-500 rounded-full text-xs text-white px-2'>
              {otherCommenters.length && `${otherCommenters.length}+`}
            </div>
          </Tooltip>}
        </p>
      </div>
      <div className={`${placeDetails?.summary ? 'h-62' : ''} overflow-auto`}>
        {/* Image slider */}
        {!isZoomApp() && <div className='relative h-30 bg-grey-200 mb-3 rounded-md border border-grey-100 overflow-hidden'>
          {placeDetails?.photos?.length &&
            <>
              {photoIndex !== 0 && <button
                onClick={() => navigateSlide(-1)}
                className=' absolute left-0 bg-gradient-to-l from-transparent to-black/80 h-full px-1.5'>
                <img src="/images/arrow-addon.svg" className="size-5 rotate-180" alt="arrow" />
              </button>}
              {photoIndex !== placeDetails?.photos?.length - 1 && <button
                onClick={() => navigateSlide(1)}
                className=' absolute right-0 bg-gradient-to-r from-transparent to-black/80 h-full px-1.5'>
                <img src="/images/arrow-addon.svg" className="size-5" alt="arrow" />
              </button>}
              <div className='flex absolute bottom-2 left-1/2 -translate-x-1/2 gap-1'>
                {placeDetails?.photos?.map((_photo, index) => {
                  return <div
                    key={index}
                    className={`size-1 rounded-full ${photoIndex === index ? 'bg-white' : 'bg-grey-500'}`}></div>;
                })}
              </div>
            </>}
          {/* {isFetching && <Skeleton className='size-full rounded-lg bg-grey-300 dark:bg-grey-300' />} */}
          {placeDetails?.photos && placeDetails?.photos[0] && (
            <img
              key={placeDetails.photos[photoIndex]}
              src={placeDetails.photos[photoIndex]}
              alt={placeName}
              className="w-full box-content h-30 object-cover pointer-events-none" />
          )}
        </div>}
        <div className='flex gap-1 mb-3 justify-between'>
          <div className=' flex flex-col gap-1'>
            <p className="font-semibold text-neutral-110 text-base">{loc1}</p>
            {loc2 && <p className="font-medium text-grey-700 text-xs">{loc2}</p>}
            <p className='text-grey-700 text-xs'>{placeDetails?.localTime}</p>
          </div>
          <div className='flex flex-1 flex-col items-end'>
            {placeDetails?.weather && Object.keys(placeDetails.weather).length && <>
              {temperature && <div className='text-grey-700 flex gap-1 text-3xl items-center'>
                <p>{temperature[0]?.toFixed(1)}</p>
                <div className='size-9.5'>
                  <button
                    className='text-grey-700 size-9.5 bg-grey-100 hover:bg-grey-200 rounded-md text-2xl transition-colors'
                    onClick={() => convertTemperature(...temperature)} >
                    {`°${temperature[1]}`}
                  </button>
                </div>
              </div>}
              <div className='flex items-center gap-1 mt-1'>
                <img
                  src={getWeatherIcon(placeDetails.weather.icon)}
                  alt={placeDetails.weather.description}
                  className='size-4' />
                <p className='text-grey-700 text-xs'>
                  {`${placeDetails.weather.description[0].toUpperCase()}${placeDetails.weather.description.slice(1)}`}
                </p>
              </div>
            </> }
          </div>
        </div>
        {/* Quick facts */}
        {placeDetails?.summary && <div className='pt-3 border-t border-grey-200'>
          <div className='flex justify-between items-center'>

            <p className='font-semibold text-sm text-grey-900'>Quick facts</p>
          </div>
          <p className='mt-3 text-sm text-grey-900'>
            {placeDetails?.summary} <Link href={`https://en.wikipedia.org/wiki/${placeDetails.displayName}`} referrerPolicy='no-referrer' target='_blank' className='underline inline'>Wikipedia</Link>
          </p>
        </div>}
        {/* {isFetching && <Skeleton className='h-40 rounded-lg dark:bg-grey-300' />} */}
      </div>
    </div>
  );
};

import Image from '@atoms/Image';
import { useRef, useEffect, useState, useContext } from 'react';
import { drawWheel } from './wheel-canvas.service';
import InteractionTitle from '@components/RunOfShow/InteractionTitle';
import { computeLuminance, getInteractionBgColorKey, interactionColorMap } from '@lib/interactionHelper';
import { TeamsDataContext } from '@components/context/TeamsContext';

const WheelCanvasResult = ({ opts, isResult = false, settings = {}, title = '', colors = {}, type = '' }) => {
  const pi = Math.PI;
  const sectorAngle = ((2 * pi) / (opts.length));

  const canvasRef = useRef(null);
  const angle = useRef(0);
  const radiusOffset = 90;

  const [radius, setRadius] = useState();
  const [value, setValue] = useState();
  const [color, setColor] = useState();
  const [canvasSide, setCanvasSide] = useState();

  const createWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const isResult = true;
    drawWheel(canvas, radius, opts, canvasSide, isResult);
  };

  const {
    getBrandLogo,
  } = useContext(TeamsDataContext);

  useEffect(() => {
    const hero = document.getElementById('hero');
    const marginY = 90; // Standard margin from top and bottom
    setRadius(parseInt(((hero?.clientHeight || 600) - radiusOffset - marginY) / 2, 10));
    setCanvasSide(((hero?.clientHeight || 600) - radiusOffset - marginY + 20));
  }, [radiusOffset]);

  const clear = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const rotate = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    clear();
    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(angle.current);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    createWheel();
    ctx.restore();
  };

  useEffect(() => {
    const index = opts.findIndex((sr) => sr.isWinner);
    if (radius) {
      if (opts[index]?.isWinner) {
        setValue(opts[index].body);
        setColor(opts[index].color);
      } else {
        setValue();
        setColor();
      }

      const i = opts.length * 2 - index;
      angle.current = i * sectorAngle - sectorAngle / 2;
      rotate();
    }
  }, [radius, opts]);
  const bgColorKey = getInteractionBgColorKey(type);

  const bgColor = (bgColorKey && colors?.[bgColorKey]) || '#000000';

  const { isBgLight } = computeLuminance(bgColor);

  return <>
    <div style={{
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: isResult ? '4px' : '',
      backgroundImage: isResult ? `url(${process.env.NEXT_PUBLIC_BUCKET_URL + settings?.winningWheelImageSrc})` : '',
    }}>
      {isResult ?
        <InteractionTitle
          title={title}
          titleFont={settings.HeadingFont}
          titleColor={colors?.[interactionColorMap.heading[type]]}
          type={type}
          logoUrl={getBrandLogo('/images/logos/brand-logo.png')}
          layoutTheme={isBgLight ? 'light' : 'dark'}
        /> : <></>
      }
      {radius
      && <div className={`flex gap-x-9 items-center ${isResult ? 'p-2 ' : ''}`}>
        <div style={{ position: 'relative', width: `${canvasSide}px`, height: `${canvasSide}px` }} >
          <canvas ref={canvasRef} width={canvasSide} height={canvasSide} />
          {
            value && <img style={{ top: `${(radius - 20)}px` }} src='/images/wheel_marker.svg' className='absolute -right-26' />
          }
        </div>
        <div className={'font-Poppins rounded-2xl relative px-4 py-3 md:w-[55%] ml-8 lg:w-[45%] h-fit font-medium flex items-center'}>
          {type === 'newWheel' &&
          <Image className="" src="/images/InteractionPage/crown.svg" />
          }
          {value && <p style={{ color: colors?.winningWheelHeadingColor }} className={'ml-[16px] text-2xl font-semibold max-w-full truncate-text text-left'} >{value}</p>}
        </div>
      </div>
      }

    </div>
  </>;
};

export default WheelCanvasResult;

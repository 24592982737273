import React, { useState, useContext, useEffect } from 'react';
import router from 'next/router';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { v4 as uuidv4 } from 'uuid';

import {
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '@atoms/new/modal';
import Button from '@atoms/Button.new';

import { StreamDataContext } from '@components/context/StreamContext';
import { TeamsDataContext } from '@components/context/TeamsContext';

import InteractionQuestion from './AdhocInteraction/InteractionQuestion';
import InteractionSetting from './AdhocInteraction/InteractionSetting';
import InteractionHeader from './AdhocInteraction/InteractionHeader';

import SettingBlock from './Presentation/main/Settings/units/SettingBlock';

import { getDefaultTitle } from '@components/Presentation/helpers';
import { getInteractionSettings } from '@services/utils.service';

import { ThemeCard } from '@components/themes';
import useFetchThemes from '@components/themes/hooks/useFetchThemes';

import {
  getCommentsDetails,
  getFeaturesCommentDetails,
  createRosQuestions,
  getQuestion,
  updateStreamSettings,
  deleteRosOptions,
  getWheelResults,
} from '@services/youtube-platform.service';

import { insertQuestion, defaultPollOptions, defaultWheelOptions, defaultRatingPollsOptions, defaultMultpleChoiceOptions, handleSaveOptions } from '@services/interactions.service';
import { useThemeStore } from '@components/themes/store/ThemeStore';
import useLoadingTimeout from '@lib/hooks/useLoadingTimeout';
import { getMCQWinners } from '@services/presentation-live';
import { isMicrosoftTeamsApp } from '@teams-app/helpers';
import { usePurchasePlan } from '@lib/hooks/usePlanSettings';
import { usePostHog } from 'posthog-js/react';
export const ALL_MCQ_SCORERS_ID = '79a4e569-ff0d-47d0-b7d5-af797f8c02c4';

const AdhocInteractionPopup = ({
  setShowRosModel,
  streamId,
  isEDU,
  isImpromptu,
  adhocFeature,
  featureType,
  featureTitle,
  streamSetting,
  defaultColors,
  linkQuestions,
  setLinkQuestions,
  currentIndex,
  setCurrentIndex,
  newQuestion,
  handleLinkFeature,
  isTrack,
  openModal,
  setOpenModal,
  setImpromptuModel,
}) => {
  const posthog = usePostHog();
  const getQuestionTitle = () => {
    if (featureTitle) {
      return featureTitle;
    } else if (['newWheel', 'Quick Questions', 'linkLibrary', 'textTrack', 'fantasticFans', 'ratingPolls'].includes(featureType)) {
      return getDefaultTitle(featureType);
    } else {
      return '';
    }
  };

  const defaultOptionsMap = {
    pollWithOptions: defaultPollOptions,
    wheel: defaultWheelOptions,
    ratingPolls: defaultRatingPollsOptions,
    multipleChoice: defaultMultpleChoiceOptions
  };

  const getOptions = () => (isEDU && !isImpromptu ? adhocFeature.options : defaultOptionsMap[featureType]) || [];

  const [selectedPanelBtn, setSelectedPanelBtn] = useState('Content');
  const [title, setTitle] = useState(getQuestionTitle());
  const [setting, setSetting] = useState(getInteractionSettings(featureType, streamSetting));
  const [color, setColor] = useState(defaultColors);
  const [options, setOptions] = useState(getOptions());
  const { defaultThemes, userThemes, defaultThemeId } = useFetchThemes();
  const [selectedThemeId, setSelectedThemeId] = useState(defaultThemeId || '');
  const [, setStore] = useThemeStore(store => store.themeMenu);

  const allInteractions = {
    id: streamId,
    title: 'Select participants who commented...',
    type: 'All Interactions'
  };

  const multipleChoiceInteraction = {
    id: ALL_MCQ_SCORERS_ID,
    title: 'Select from all multiple choice participants',
    type: 'multipleChoice'
  };

  const [isLoading, setIsLoading] = useLoadingTimeout(30000);
  const [winnerWheelsData, setWinnerWheelData] = useState([allInteractions]);
  const [selectedInteraction, setSelectedInteraction] = useState(allInteractions);

  const getUsers = async () => {
    const response = await getCommentsDetails(streamId, setting.wheelShowUser);
    if (response.status) {
      return response.entity.length;
    }
  };

  const getInteractionUsers = async (interactionId) => {
    const response = await getFeaturesCommentDetails(streamId, interactionId, setting.wheelShowUser);
    if (response.status) {
      return response.entity.length;
    }
  };

  useEffect(() => {
    if (!userThemes || !defaultThemes) return;
    setStore({ themes: { userThemes, defaultThemes } });
    updateTheme([...userThemes, ...defaultThemes].find(theme => theme.id === defaultThemeId) || {});
  }, [userThemes, defaultThemes, defaultThemeId]);

  useEffect(() => {
    setSelectedThemeId(defaultThemeId);
  }, [defaultThemeId]);

  const { teamContext } = useContext(TeamsDataContext);

  const {
    setFeatureQuestion,
    setFeatureSettings,
    setFeatureBrandColours,
    isLinkActive
  } = useContext(StreamDataContext);

  async function getInteractionDetails(winnerWheelsData) {
    const interactionDetails = await Promise.all(
      winnerWheelsData.map(async (interaction) => {
        const totalCommenters = interaction.id === streamId
          ? await getUsers()
          : await getInteractionUsers(interaction.id);

        return {
          ...interaction,
          totalCommenters,
        };
      })
    );

    return interactionDetails;
  }

  const fetchWheelResults = async () => {
    if (featureType !== 'newWheel') return;
    setIsLoading(true);

    const response = await getWheelResults(streamId);
    if (response.status) {
      const interactionsWithoutMultipleChoice = response.entity.filter(interaction => interaction.type !== 'multipleChoice');
      const interactionDetails = await getInteractionDetails([allInteractions, ...response.entity]);
      let totalMultipleChoiceInteractions = response.entity.length - interactionsWithoutMultipleChoice.length;
      if (totalMultipleChoiceInteractions > 1) {
        const totalMultipleChoiceCommenters = await getMCQWinners(streamId);
        multipleChoiceInteraction.totalCommenters = totalMultipleChoiceCommenters.entity.length || 0;
        interactionDetails.push(multipleChoiceInteraction);
      }
      setSelectedInteraction(interactionDetails.find(interaction => interaction.id === streamId));
      setWinnerWheelData(interactionDetails);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWheelResults();
  }, [featureType]);
  const { settings: planSettings } = usePurchasePlan();

  const updateTotalCommenters = async () => {
    const interactionDetails = await getInteractionDetails(winnerWheelsData);
    setSelectedInteraction(interactionDetails.find(interaction => interaction.id === selectedInteraction.id));
    setWinnerWheelData(interactionDetails);
  };

  useEffect(() => {
    updateTotalCommenters();
  }, [setting.wheelShowUser]);

  const addNewOption = () => {
    const number = Math.max(0, ...options.map(o => +o.option_number)) + 1;

    if (number <= 10 && featureType === 'pollWithOptions') {
      const option = { key: uuidv4(), body: '', option_number: number };
      setOptions([...options, option]);
    }

    if (number <= 10 && featureType === 'multipleChoice') {
      const option = { key: uuidv4(), body: '', option_number: number };
      setOptions([...options, option]);
    }
  };

  const deleteOption = async (option) => {
    const nonDeletedOptions = options.filter((opt) => opt !== option);
    const updatedOptions = nonDeletedOptions.map((opt, index) => ({ ...opt, option_number: index + 1 }));

    setOptions(updatedOptions);

    // await deleteRosOptions(option.id);
  };

  const updateSetting = (key, value) => {
    let data;

    switch (key) {
      case 'DisplayStyle':
        data = !value ? 'noOfVotes' : 'percentage';
        break;
      case 'transientThoughtDisplayStyle':
        data = !value ? 'bubbles' : 'cloud';
        break;
      case 'OptionsList':
        data = !value ? 'character' : 'number';
        break;
      default:
        data = value;
    }

    setSetting(prevSetting => ({
      ...prevSetting,
      [key]: data
    }));
  };

  const updateTheme = (theme) => {
    setSelectedThemeId(theme.id);

    setColor((prevColors) => {
      const colors = { ...theme.config.colors };

      if (!('multipleChoiceImageSrc' in theme.config.images)) {
        colors.multipleChoiceBgColor = theme.config.default.bgColor;
        colors.multipleChoiceHeadingColor = theme.config.default.headingColor;
        colors.multipleChoiceChartColor = '#F97B5F';
        colors.multipleChoiceLabelColor = '#FFFFFF';
      }

      return { ...prevColors, ...colors };
    });

    setSetting((prevSetting) => {
      const fonts = theme.config.textFontFamily;
      const images = theme.config.images;

      let FontStyle = prevSetting.FontStyle;

      const newSetting = { ...prevSetting, mapConfig: { ...prevSetting.mapConfig, style: theme?.config?.settings?.mapConfig?.style || 'Standard' } };

      const featureTypeSettings = {
        map: () => {
          newSetting.mapImageSrc = images.mapImageSrc;
          newSetting.mapImageOpacity = images.mapImageOpacity;
        },
        freeForm: () => {
          FontStyle = fonts.pollFontStyle;
          newSetting.pollImageSrc = images.pollImageSrc;
          newSetting.pollImageOpacity = images.pollImageOpacity;
        },
        pollWithOptions: () => {
          FontStyle = fonts.pollWithOptionsFontStyle;
          newSetting.pollWithOptionsImageSrc = images.pollWithOptionsImageSrc;
          newSetting.pollWithOptionsImageOpacity = images.pollWithOptionsImageOpacity;
        },
        wordCloud: () => {
          FontStyle = fonts.wordCloudFontStyle;
          newSetting.wcImageSrc = images.wcImageSrc;
          newSetting.wcImageOpacity = images.wcImageOpacity;
        },
        emojisEverywhere: () => {
          newSetting.emojisEverywhereImageSrc = images.emojisEverywhereImageSrc;
          newSetting.emojisEverywhereImageOpacity = images.emojisEverywhereImageOpacity;
        },
        fantasticFans: () => {
          FontStyle = fonts.fantasticFansFontStyle;
          newSetting.ffImageSrc = images.ffImageSrc;
          newSetting.ffImageOpacity = images.ffImageOpacity;
        },
        textTrack: () => {
          FontStyle = fonts.textTrackFontStyle;
          newSetting.textTrackImageSrc = images.textTrackImageSrc;
          newSetting.textTrackImageOpacity = images.textTrackImageOpacity;
        },
        transientThought: () => {
          FontStyle = fonts.transientThoughtFontStyle;
          newSetting.ttImageSrc = images.ttImageSrc;
          newSetting.ttImageOpacity = images.ttImageOpacity;
        },
        talkingTiles: () => {
          newSetting.talkingTilesImageSrc = images.talkingTilesImageSrc;
          newSetting.talkingTilesImageOpacity = images.talkingTilesImageOpacity;
        },
        wheel: () => {
          newSetting.choiceCircleImageSrc = images.choiceCircleImageSrc;
          newSetting.choiceCircleImageOpacity = images.choiceCircleImageOpacity;
        },
        newWheel: () => {
          newSetting.winningWheelImageSrc = images.winningWheelImageSrc;
          newSetting.winningWheelImageOpacity = images.winningWheelImageOpacity;
        },
        linkLibrary: () => {
          newSetting.linkLibraryImageSrc = images.linkLibraryImageSrc;
          newSetting.linkLibraryImageOpacity = images.linkLibraryImageOpacity;
        },
        'Quick Questions': () => {
          newSetting.quickQuestionImageSrc = images.quickQuestionImageSrc;
          newSetting.quickQuestionImageOpacity = images.quickQuestionImageOpacity;
        },
        ratingPolls: () => {
          newSetting.ratingPollsImageSrc = images.ratingPollsImageSrc;
          newSetting.ratingPollsImageOpacity = images.ratingPollsImageOpacity;
        },
        multipleChoice: () => {
          FontStyle = fonts.multipleChoiceFontStyle || 'Sans-Serif';
          if (!('multipleChoiceImageSrc' in theme.config.images)) {
            newSetting.HeadingFont = theme.config.default.headingFontFamily;
            newSetting.multipleChoiceImageSrc = theme.config.default.imageSrc;
            newSetting.multipleChoiceImageOpacity = theme.config.default.imageOpacity;
          } else {
            newSetting.HeadingFont = theme.config.default.headingFontFamily;
            newSetting.multipleChoiceImageSrc = images.multipleChoiceImageSrc;
            newSetting.multipleChoiceImageOpacity = images.multipleChoiceImageOpacity;
          }
        }
      };

      if (featureTypeSettings[featureType]) {
        featureTypeSettings[featureType]();
      }

      return { ...newSetting, FontStyle };
    });
  };

  const setSelectedQuestions = (question) => {
    if (isTrack) {
      handleLinkFeature(question);
    } else {
      setFeatureQuestion(question);
    }
  };

  const handleLinkQuestions = (response, isUserTemplate, createdQuestionResponse) => {
    if (isMicrosoftTeamsApp()) return;
    if (isLinkActive && isUserTemplate && response.entity.linkedQuestions) {
      setLinkQuestions(response.entity.linkedQuestions);
      setCurrentIndex(currentIndex + 1);
    } else if (!isLinkActive) {
      const updatedQuestions = insertQuestion(
        [...linkQuestions],
        { ...createdQuestionResponse, isAdhoc: true },
        currentIndex + 1
      );
      setLinkQuestions(updatedQuestions);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleAddTrackInteractionToROS = async (createQuestion) => {
    const response = await createRosQuestions({ ...createQuestion });
    if (response.status) {
      const { entity: { createdQuestionResponse, isUserTemplate } } = response;

      setSelectedQuestions(createdQuestionResponse);

      handleLinkQuestions(response, isUserTemplate, createdQuestionResponse);

      setShowRosModel(false);
    }
  };

  const redirectToFeature = () => {
    if (openModal === 'default') setOpenModal('showMenu');
    const { userDetailsId } = router.query;
    router.replace({ pathname: `/streams/${streamId}/ros`, query: { userDetailsId } });
  };

  const handleStartAdhocInteraction = async () => {
    const trackInteractions = [
      'Quick Questions',
      'linkLibrary',
      'textTrack',
      'fantasticFans'
    ];

    let newSetting = featureType === 'map' ? { ...setting, mapConfig: { style: 'Standard' } } : setting;

    const createQuestion = {
      title,
      live_stream_id: streamId,
      userId: teamContext?.oid,
      type: featureType,
      questionNumber: currentIndex + 1,
      setting: newSetting,
      color,
      metadata: {}
    };

    if (featureType === 'newWheel') {
      createQuestion.metadata.featureId = selectedInteraction.id;
      createQuestion.metadata.featureType = selectedInteraction.type;
    }

    setFeatureSettings(newSetting);
    setFeatureBrandColours(color);

    if (trackInteractions.includes(featureType)) {
      await handleAddTrackInteractionToROS(createQuestion);
      redirectToFeature();
      return;
    }

    posthog?.capture('Adhoc Interaction Started', {
      streamId,
      featureType,
      title,
    });

    if (newQuestion) {
      const response = await createRosQuestions({ ...createQuestion });
      if (response.status) {
        const { entity: { createdQuestionResponse, isUserTemplate } } = response;
        const questionId = createdQuestionResponse.id;

        if (featureType === 'pollWithOptions' || featureType === 'wheel' || featureType === 'multipleChoice' || featureType === 'ratingPolls') {
          await handleSaveOptions(featureType, options, questionId);
        }

        const fetchQuestion = await getQuestion(questionId);

        if (fetchQuestion.status) {
          const fetchedQuestion = fetchQuestion.entity;
          setSelectedQuestions(fetchedQuestion);
          createdQuestionResponse.options = fetchQuestion.entity.options;
        } else {
          const defaultQuestion = {
            id: questionId,
            type: featureType,
            title,
            setting,
            color,
            options,
            metadata: {}
          };
          if (featureType === 'newWheel') {
            defaultQuestion.metadata.featureId = selectedInteraction.id;
            defaultQuestion.metadata.featureType = selectedInteraction.type;
          }
          setSelectedQuestions(defaultQuestion);
          createdQuestionResponse.options = options;
        }

        await updateStreamSettings(streamId, { settings: setting });

        handleLinkQuestions(response, isUserTemplate, createdQuestionResponse);
        redirectToFeature();
      }
    }
  };

  const hasSingleOption = options.filter(option => option.body.trim()).length <= 1;

  return (<ModalContent className="text-black">
    <ModalHeader>
      <InteractionHeader
        featureType={featureType}
        selectedPanelBtn={selectedPanelBtn}
        setSelectedPanelBtn={setSelectedPanelBtn}
      />
    </ModalHeader>
    <ModalBody className="hide-scrollbar pt-0">
      {(selectedPanelBtn === 'Content') && (
        <>
          <div className="flex flex-col">
            <InteractionQuestion
              featureType={featureType}
              title={title}
              setTitle={setTitle}
              options={options}
              setOptions={setOptions}
              addNewOption={addNewOption}
              deleteOption={deleteOption}
              setting={setting}
              updateSetting={updateSetting}
            />
            <InteractionSetting
              featureType={featureType}
              setting={setting}
              updateSetting={updateSetting}
              winnerWheelsData={winnerWheelsData}
              selectedInteraction={selectedInteraction}
              setSelectedInteraction={setSelectedInteraction}
            />
          </div>
        </>
      )}
      {selectedPanelBtn === 'Themes' && (
        <div className="flex flex-col">
          {userThemes?.length > 0 && <SettingBlock title="Your themes" addPadding={false}>
            <div className='grid grid-cols-3 gap-4'>
              {userThemes?.map(theme => <ThemeCard
                height='h-[107px]'
                key={theme.id}
                theme={theme}
                isDefault={theme.id === defaultThemeId}
                isSelected={theme.id === selectedThemeId}
                showMenu={false}
                isDisabled={!planSettings?.themeCustomizationAllowed}
                onClick={() => {
                  updateTheme(theme);
                }}
              />)}
            </div>
          </SettingBlock>}
          <SettingBlock title="Standard themes" addPadding={false}>
            <div className='grid grid-cols-3 gap-4'>
              {defaultThemes?.map(theme => <ThemeCard
                height='h-[107px]'
                key={theme.id}
                theme={theme}
                isStandardTheme
                isDefault={defaultThemeId ? theme.id === defaultThemeId : theme.name === 'Dark'}
                isSelected={theme.id === selectedThemeId}
                showMenu={false}
                onClick={() => {
                  updateTheme(theme);
                }}
              />)}
            </div>
          </SettingBlock>
        </div>
      )}
    </ModalBody>
    <ModalFooter>
      <div className="w-full flex justify-between">
        <div>
          <Button
            size="lg"
            variant = "tertiary"
            onClick={() => {
              setShowRosModel(false);
              setImpromptuModel(true);
            }} >
            <ArrowBackIcon fontSize="small" />
            <span className="ml-1">Back</span>
          </Button>
        </div>
        <div className="flex justify-end gap-2">
          <Button
            size="lg"
            variant="secondary"
            onClick={() => setShowRosModel(false)}
          >
          Cancel
          </Button>
          <Button
            size="lg"
            variant="primary"
            disabled={((featureType === 'wheel') && hasSingleOption) || (featureType === 'newWheel' && selectedInteraction?.totalCommenters === 0 && selectedInteraction.type !== 'All Interactions')}
            tooltipMessage={(featureType === 'newWheel' && selectedInteraction?.totalCommenters === 0 && selectedInteraction.type !== 'All Interactions') && 'There haven\'t been enough commenters to run this interaction.'}
            onClick={handleStartAdhocInteraction}
          >
          Go
          </Button>
        </div>
      </div>
    </ModalFooter>
  </ModalContent>);
};

export default AdhocInteractionPopup;

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useEffect, useReducer, useRef, useState, forwardRef, useImperativeHandle
} from 'react';
import router, { useRouter } from 'next/router';
import { getTextTrackComments, updateEngageType } from '@services/youtube-platform.service';
import { isEqual } from 'lodash';
import WordChart from './WordChart';
import useNotificationTimer from '@lib/hooks/useNotificationTimer ';
import { chromaPaletteParser } from './helper/chromaPaletteParser';
import { colorPalette } from '@lib/constants';
import useFonts from '@lib/hooks/useFonts';

const TextTrack = forwardRef(({
  isResult = false,
  streamId,
  platformType,
  currentRosFeature,
  setting,
  color,
  setShowNotification,
  isFullScreen,
  splitDiv,
  analyticsData,
  livestreamData,
  layoutMode,
  showInstantCustomisationPopup = false
}, ref) => {
  const getSettings = () => {
    if (isResult) {
      return setting;
    } else if (currentRosFeature.setting) {
      return currentRosFeature.setting;
    }
    return setting;
  };

  const getColors = () => {
    if (isResult) {
      return color;
    } else if (currentRosFeature.color) {
      return currentRosFeature.color;
    }
    return color;
  };

  const [textTrackData, setTextTrackData] = useState([]);
  const [textTrackSettings, setTextTrackSettings] = useState(getSettings());
  const [textTrackColors, setTextTrackColors] = useState(getColors());
  const previousDataRef = useRef([]);
  const highchartsRef = useRef(null);

  const newPalette = chromaPaletteParser().parse(colorPalette, textTrackColors?.textTrackBgColor);

  const { getFontStyle } = useFonts();
  const { fontFamily } = getFontStyle(textTrackSettings?.FontStyle || 'Sans-Serif');
  const bgColor = isResult ? (livestreamData?.colors?.textTrackBgColor || '#ffffff') : 'rgba(255, 255, 255, 0.0)';

  useNotificationTimer(currentRosFeature, textTrackData, setShowNotification);

  const getTextTrackData = async () => {
    const response = await getTextTrackComments(streamId, textTrackSettings.ProfanityFilter);
    if (response.status) {
      return response.entity;
    }
    return [];
  };

  const initialChartOptions = {
    exporting: { enabled: false },
    chart: {
      backgroundColor: livestreamData?.settings?.textTrackImageSrc?.length > 0 ? 'transparent' : bgColor
    },
    plotOptions: {
      series: {},
      chart: { height: '100%' },
      wordcloud: {
        rotation: {
          from: 0,
          orientations: textTrackSettings?.Vertical ? 2 : 1,
          to: textTrackSettings?.Vertical ? 90 : 0
        },
        color: textTrackColors?.textTrackWordColor || '#319795',
        colorByPoint: textTrackColors?.textTrackMultipleColors,
        colors: textTrackColors?.textTrackMultipleColors ? newPalette : [textTrackColors?.textTrackWordColor || '#000000'],
        style: {
          fontFamily
        }
      }
    },
    credits: {
      enabled: false
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    series: [{
      type: 'wordcloud',
      data: getTextTrackData(),
      name: 'Count'
    }],
    title: { text: '' }
  };

  const [chartOptions, dispatch] = useReducer((state, action) => {
    if (action.type === 'UPDATE_COLOR') {
      return {
        ...state,
        exporting: { enabled: false },
        chart: {
          backgroundColor: livestreamData?.settings?.textTrackImageSrc ? 'transparent' : bgColor
        },
        plotOptions: {
          series: {},
          chart: { height: '100%' },
          wordcloud: {
            rotation: {
              from: 0,
              orientations: textTrackSettings?.Vertical ? 2 : 1,
              to: textTrackSettings?.Vertical ? 90 : 0
            },
            color: action?.data?.textTrackWordColor || textTrackColors?.textTrackWordColor || '#319795',
            colorByPoint: textTrackColors?.textTrackMultipleColors,
            colors: textTrackColors?.textTrackMultipleColors ? newPalette : [action?.data?.textTrackWordColor || textTrackColors?.textTrackWordColor || '#319795'],
            style: {
              fontFamily
            }
          }
        },
        credits: {
          enabled: false
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 1400
            },
            chartOptions: {
              legend: {
                enabled: false
              }
            }
          }]
        },
        series: [{
          type: 'wordcloud',
          data: action.data,
          name: 'Count'
        }],
      };
    }
    if (action.type === 'UPDATE_SETTING') {
      return {
        ...state,
        exporting: { enabled: false },
        chart: {
          backgroundColor: livestreamData?.settings?.textTrackImageSrc ? 'transparent' : bgColor
        },
        plotOptions: {
          series: {},
          chart: { height: '100%' },
          wordcloud: {
            rotation: {
              from: 0,
              orientations: action?.data?.Vertical || textTrackSettings?.Vertical ? 2 : 1,
              to: action?.data?.Vertical || textTrackSettings?.Vertical ? 90 : 0
            },
            color: textTrackColors?.textTrackWordColor || '#319795',
            colorByPoint: textTrackColors?.textTrackMultipleColors,
            colors: textTrackColors?.textTrackMultipleColors ? newPalette : [textTrackColors?.textTrackWordColor || '#319795'],
            style: {
              fontFamily
            }
          }
        },
        credits: {
          enabled: false
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 1400
            },
            chartOptions: {
              legend: {
                enabled: false
              }
            }
          }]
        },
        series: [{
          type: 'wordcloud',
          data: action.data,
          name: 'Count'
        }],
      };
    }

    return {
      ...state,
      exporting: { enabled: false },
      chart: {
        backgroundColor: livestreamData?.settings?.textTrackImageSrc ? 'transparent' : bgColor
      },
      plotOptions: {
        series: {},
        chart: { height: '100%' },
        wordcloud: {
          rotation: {
            from: 0,
            orientations: textTrackSettings?.Vertical ? 2 : 1,
            to: textTrackSettings?.Vertical ? 90 : 0
          },
          color: textTrackColors?.textTrackWordColor || '#319795',
          colorByPoint: textTrackColors?.textTrackMultipleColors,
          colors: textTrackColors?.textTrackMultipleColors ? newPalette : [textTrackColors?.textTrackWordColor || '#000000'],
          style: {
            fontFamily
          }
        }
      },
      credits: {
        enabled: false
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 1400
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      series: [{
        type: 'wordcloud',
        data: action.data,
        name: 'Count'
      }],
    };
  }, initialChartOptions);

  const joinType = useRouter().query?.joinType;

  const handleTextTrackComments = async () => {
    const response = await getTextTrackComments(streamId, textTrackSettings?.ProfanityFilter);
    const hasDataChanged = !isEqual(response.entity, previousDataRef.current);
    if (hasDataChanged) {
      previousDataRef.current = response.entity;
      setTextTrackData(response.entity);
    }
  };

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) return;

    updateEngageType(streamId, 'textTrack', { platformType });
  }, [currentRosFeature?.id, isResult]);

  useEffect(() => {
    if (currentRosFeature?.setting) {
      setTextTrackSettings(currentRosFeature.setting);
      dispatch({ type: 'UPDATE_SETTING', settings: currentRosFeature.setting, data: textTrackData });
    }
  }, [currentRosFeature?.setting]);

  useEffect(() => {
    if (currentRosFeature?.color) {
      setTextTrackColors(currentRosFeature.color);
      dispatch({ type: 'UPDATE_COLOR', colors: currentRosFeature.color, data: textTrackData });
    }
  }, [currentRosFeature?.color]);

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) return;

    if (!isResult) {
      const interval = setInterval(() => {
        handleTextTrackComments();
      }, 1000);
      return () => clearInterval(interval);
    }
    handleTextTrackComments();
    return undefined;
  }, [currentRosFeature?.id, textTrackSettings]);

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) setTextTrackData(analyticsData);
  }, [isResult, analyticsData]);

  useEffect(() => {
    dispatch({ data: textTrackData });
  }, [textTrackData]);

  const stopCurrentFeature = () => {
    router.push({ pathname: `/streams/${streamId}`, query: joinType && { joinType } });
  };

  useImperativeHandle(ref, () => ({ stopCurrentFeature }));

  useEffect(() => {
    if (isResult && analyticsData?.length > 0) return;
    if (textTrackData.length > 0 && setShowNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 500);
    }
  }, [currentRosFeature?.id, textTrackData]);

  return (
    <div className='w-full h-full overflow-hidden rounded-lg flex'>
      <WordChart
        backgroundColor={bgColor}
        highchartsRef={highchartsRef}
        chartOptions={chartOptions}
        isFullScreen={isFullScreen}
        splitDiv={splitDiv}
        containerProps={{ style: { width: '100%', minHeight: '100%' } }}
        livestreamData={livestreamData}
        bgImg={livestreamData?.settings?.textTrackImageSrc}
        bgImgOpacity={(livestreamData?.settings?.textTrackImageOpacity ?? 0) / 100}
        showInstantCustomisationPopup={showInstantCustomisationPopup}
        textAnalytics={true}
      />
    </div>
  );
});

export default TextTrack;

import React, {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';

import { StreamDataContext } from '@components/context/StreamContext';
import { pushDataLayerForEvent } from '@lib/gtag';
import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import { hexToRGB } from '@services/utils.service';
import { mapStylesMap, parseColorToHex, parseColorToRgba } from '@services/interactions.service';

import PlaceDetailsPopup from './PlaceDetailsPopup';
import useCountdownTimer from '@lib/hooks/useCountdownTimer';
import CustomMapMarker from './CustomMapMarker';
import { getMapPopupDetails } from '@services/presentation-live';

const MapBoxComponent = ({
  interactionState,
  mapId,
  colors,
  settings,
  mapData,
  currentRosResult = [],
  height
}) => {
  mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const [point, setPoint] = useState([]);
  const pointsRef = useRef([]);
  const newPointsRef = useRef([]);
  const viewRegion = { center: [29, 9.88], zoom: 1.3 };
  const mapHeight = height ? '100%' : (mapData?.height ? mapData.height : '100%');
  const { mapBox } = useContext(StreamDataContext);
  const oneUoneV = settings?.mapConfig?.oneUoneV || false;
  const placeInfo = useRef({});

  const mapboxStyles = {
    Standard: 'mapbox://styles/mapbox/streets-v12',
    Light: 'mapbox://styles/sa-techassets/cm6p63upe002l01qr6mmff01a',
    Dark: 'mapbox://styles/sa-techassets/cm6p669lt00sl01qq8c2phu78',
    Sky: 'mapbox://styles/sa-techassets/cm6p67nxz00rj01ry66zrajr8',
    Globe: 'mapbox://styles/mapbox/streets-v12',
    Comic: 'mapbox://styles/sa-techassets/cm2xfw6d7005501qz3e4sd0ke',
    Pencil: 'mapbox://styles/sa-techassets/cm2xeq2s8004y01qt0ifadj6o',
    Blueprint: 'mapbox://styles/sa-techassets/cm2xeq3i500jm01pa9l8kgwdq',
    Bright: 'mapbox://styles/sa-techassets/cm2xejuij004x01qzb6qk3614',
    'Bright (Globe)': 'mapbox://styles/sa-techassets/cm2xen9jo00qy01pm3plb3ds1',
  };

  const mapboxStyle = settings?.mapConfig?.style || 'Standard';
  const mapboxStyleURL = mapboxStyles[mapboxStyle];

  const popupRef = useRef(new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true,
    className: 'mapbox-popup',
  }));

  const { startTimer, stopTimer, count } = useCountdownTimer();

  function paintMapLayers(mapInstance) {
    // Uncomment if you want to see the layers in the console
    // console.log('mapbox: layers:', mapInstance.getStyle());

    if (!mapInstance || !mapboxStyle || !colors) return;

    const mapLayers = mapStylesMap[mapboxStyle];
    if (!mapLayers) return;

    if (!mapInstance.isStyleLoaded()) {
      mapInstance.once('styledata', () => paintMapLayers(mapInstance));
      return;
    }

    try {
      mapLayers.water?.forEach(([name, property]) => mapInstance.setPaintProperty(name, property, colors.mapWaterColor));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Map.js load waterColor style has error!', err);
    }
    try {
      mapLayers.land?.forEach(([name, property]) => mapInstance.setPaintProperty(name, property, colors.mapLandColor));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Map.js load landColor style has error!', err);
    }
  }

  const cleanUp = () => {
    const mapInstance = map.current;

    setPoint([]);
    pointsRef.current = [];
    newPointsRef.current = [];

    if (mapInstance.getSource('newPoint')) {
      mapInstance.getSource('newPoint').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
    if (mapInstance.getSource('point')) {
      mapInstance.getSource('point').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
  };

  const renderPlacePopup = async (features) => {
    const htmlStr = `<div class="w-[220px] ${features[0].properties.placeType === 11 ? '' : 'min-h-52'} flex flex-col" id="place-details-popup"></div>`;

    const places = Object.groupBy(features, ({ properties }) => properties.place);

    const fetchPromises = Object.entries(places)
      .filter(([place]) => !placeInfo.current[place])
      .map(async ([place, featureGroup]) => {
        const placeType = featureGroup[0].properties.placeType;
        const username = featureGroup[0].properties.username;
        const otherCommenters = featureGroup.slice(1).map(({ properties }) => properties.username);

        try {
          const data = await getMapPopupDetails(place, placeType);
          if (!data.status || data.entity.weather?.temp === undefined) return;

          placeInfo.current[place] = { placeName: place, placeType, username, otherCommenters, placeDetails: data.entity };
        } catch (error) {
          console.error(error);
        }
      });

    await Promise.all(fetchPromises);

    const { coordinates } = features[0].geometry;
    popupRef.current.setLngLat(coordinates).setHTML(htmlStr).addTo(map.current);
    const popupContainer = document.querySelector('#place-details-popup');
    if (!popupContainer) return;
    const root = ReactDOM.createRoot(popupContainer);

    const place = placeInfo.current[features[0].properties.place];
    if (!place) return;

    // Render the PlacePopup component into the container using ReactDOM
    root.render(
      <PlaceDetailsPopup
        place={place}
        onMouseEnter={() => stopTimer()}
        onMouseOut={() => startTimer(10)}
      />
    );

    startTimer(10);
  };

  const createMap = (features) => {
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapboxStyleURL,
      center: viewRegion.center,
      zoom: viewRegion.zoom,
      projection: mapboxStyle.includes('Globe') ? 'globe' : 'mercator',
      testMode: true // need to make this configurable
    });

    const mapInstance = map.current;

    mapInstance.on('load', async () => {
      paintMapLayers(mapInstance);

      addPointSource(mapInstance, mapData?.features);

      mapInstance.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'point'
      });

      if (features.length > 0) {
        const places = Object.groupBy(features, ({ properties }) => properties.place);
        const latestPlace = features[features.length - 1]?.properties?.place;

        renderMarkers(mapInstance, places, latestPlace);
      }

      addClustersLayer(mapInstance);
      addClusterCountLayer(mapInstance);
      addUnclusteredPointLayer(mapInstance);
      addNewPointSource(mapInstance, []);
      addNewPointsLayer(mapInstance);

      mapInstance.on('click', 'clusters', (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance.getSource('point').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom
            });
          }
        );
      });

      mapInstance.on('mouseenter', 'points', () => {
        mapInstance.getCanvas().style.cursor = 'pointer';
      });

      mapInstance.on('mouseleave', 'points', () => {
        mapInstance.getCanvas().style.cursor = '';
      });

      mapInstance.on('mouseenter', 'unclustered-point', async (e) => {
        renderPlacePopup(e.features);
      });

      mapInstance.on('mouseleave', 'unclustered-point', () => {
        mapInstance.getCanvas().style.cursor = '';
      });

      mapInstance.on('mouseenter', 'clusters', () => {
        mapInstance.getCanvas().style.cursor = 'pointer';
      });

      mapInstance.on('mouseleave', 'clusters', () => {
        mapInstance.getCanvas().style.cursor = '';
      });
    });

    mapInstance.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-left');

    return mapInstance;
  };

  const updateMap = (features) => {
    const mapInstance = map.current;

    if (features.length && mapInstance.getSource('newPoint') && mapInstance.getSource('point')) {
      const latestPoint = features[0];
      mapInstance.getSource('newPoint').setData({
        type: 'FeatureCollection',
        features: [latestPoint]
      });

      const existingPoints = pointsRef.current;
      const newPoints = features;
      const allPoints = oneUoneV ? [...newPoints] : [...existingPoints, ...newPoints];

      mapInstance.getSource('point').setData({
        type: 'FeatureCollection',
        features: allPoints
      });

      const places = Object.groupBy(allPoints, ({ properties }) => properties.place);
      const latestPlace = allPoints[allPoints.length - 1]?.properties?.place;

      renderMarkers(mapInstance, places, latestPlace);

      pointsRef.current = oneUoneV ? [...features] : [...pointsRef.current, ...features];
      newPointsRef.current = features;
      setPoint([...pointsRef.current]);

      if (count !== 0) return;
      renderPlacePopup(features);
    }
  };

  const setMap = (features) => {
    try {
      if (features) {
        const mapInstance = map.current;

        if (!mapInstance.getSource('point')) {
          setTimeout(() => {
            setMap(features);
          }, 500);
          return;
        }

        if (features && features.length && mapInstance.getSource('point')) {
          mapInstance.getSource('point').setData({
            type: 'FeatureCollection',
            features
          });

          mapInstance.getSource('newPoint').setData({
            type: 'FeatureCollection',
            features
          });

          pointsRef.current = features;
          newPointsRef.current = features;
          setPoint([...pointsRef.current]);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    let features = mapData?.features?.length ? mapData.features : currentRosResult[0]?.features || [];
    const mapInstance = createMap(features);

    pointsRef.current = [];
    newPointsRef.current = [];
    setPoint([]);

    setMap(features);

    return () => {
      mapInstance.remove();
    };
  }, [mapId]);

  const addPointSource = (mapInstance, points) => {
    mapInstance.addSource('point', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: points || []
      },
      cluster: true,
      clusterMaxZoom: 4,
      clusterRadius: 50,
      tolerance: 0
    });
  };

  const addNewPointSource = (mapInstance, newPoints) => {
    mapInstance.addSource('newPoint', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: newPoints || []
      }
    });
  };

  const addClustersLayer = (mapInstance) => {
    mapInstance.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'point',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': hexToRGB(colors?.smallCircleColor || '#FF0064', 0.4),
        'circle-radius': 20
      }
    });
  };

  const addClusterCountLayer = (mapInstance) => {
    mapInstance.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'point',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  };

  const addUnclusteredPointLayer = (mapInstance) => {
    mapInstance.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'point',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': colors?.individualPointColor || '#FF0064',
        'circle-radius': 6,
        // 'circle-stroke-width': 1,
        // 'circle-stroke-color': `${hexToRGB(colors.individualPointColor, 0.4)}`
      }
    });
  };

  const addNewPointsLayer = (mapInstance) => {
    mapInstance.addLayer({
      id: 'newpoints',
      type: 'circle',
      source: 'newPoint',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': colors?.latestPointColor || '#9567F9',
        'circle-radius': 10,
      //  'circle-stroke-width': 8,
      //  'circle-stroke-color': `${hexToRGB(colors.latestPointColor, 0.4)}`
      }
    });
  };

  const updateMapSourcesAndLayers = () => {
    const mapInstance = map.current;
    if (!mapInstance) return;

    const projectionType = mapboxStyle.includes('Globe') ? 'globe' : 'mercator';
    mapInstance.setProjection(projectionType);

    mapInstance.setStyle(mapboxStyleURL);

    mapInstance.on('style.load', () => {
      if (!mapInstance.getSource('point')) {
        addPointSource(mapInstance, pointsRef.current);
      }

      if (!mapInstance.getLayer('clusters')) {
        addClustersLayer(mapInstance);
      }

      if (!mapInstance.getLayer('cluster-count')) {
        addClusterCountLayer(mapInstance);
      }

      if (!mapInstance.getLayer('unclustered-point')) {
        addUnclusteredPointLayer(mapInstance);
      }

      if (!mapInstance.getSource('newPoint')) {
        addNewPointSource(mapInstance, newPointsRef.current);
      }

      if (!mapInstance.getLayer('newpoints')) {
        addNewPointsLayer(mapInstance);
      }
    });
  };

  useEffect(() => {
    updateMapSourcesAndLayers();
  }, [mapboxStyle]);

  useEffect(() => {
    const mapInstance = map.current;
    paintMapLayers(mapInstance);
  }, [colors?.mapWaterColor, colors?.mapLandColor]);

  const renderMarkers = (mapInstance, places, latestPlace) => {
    Object.entries(places).forEach(([place, featureGroup]) => {
      const { coordinates } = featureGroup[0].geometry;
      const uniqueUsers = new Set(featureGroup.map(({ properties }) => properties.username));
      const totalUniqueUsers = uniqueUsers.size;

      const markerColor = place === latestPlace
        ? parseColorToHex(colors?.latestPointColor || '#9567F9')
        : parseColorToHex(colors?.individualPointColor || '#FF0064');

      const customMarker = document.createElement('div');
      const root = ReactDOM.createRoot(customMarker);
      root.render(
        <CustomMapMarker
          feature={featureGroup[0]}
          totalUniqueUsers={totalUniqueUsers}
          markerColor={markerColor}
        />
      );

      new mapboxgl.Marker(customMarker)
        .setLngLat([coordinates[0], coordinates[1]])
        .addTo(mapInstance);
    });
  };

  useEffect(() => {
    const mapInstance = map.current;
    const allPoints = pointsRef.current;

    if (!mapInstance || !mapInstance.getSource('point') || allPoints.length === 0) return;

    document.querySelectorAll('.mapboxgl-marker').forEach(marker => marker.remove());

    const places = Object.groupBy(allPoints, ({ properties }) => properties.place);
    const latestPlace = allPoints[allPoints.length - 1]?.properties?.place;

    renderMarkers(mapInstance, places, latestPlace);
  }, [colors?.latestPointColor, colors?.individualPointColor]);

  useEffect(() => {
    const mapInstance = map.current;
    if (!mapInstance) return;

    if (mapInstance.getLayer('clusters')) {
      mapInstance.setPaintProperty('clusters', 'circle-color', hexToRGB(colors?.smallCircleColor || '#FF0064', 0.4));
    }
  }, [colors?.smallCircleColor]);

  useEffect(() => {
    if (mapBox.type === 'viewRegion') { map.current.jumpTo(mapBox.viewRegion); return; }
    if (mapBox.type === 'update') { updateMap(mapBox.features); return; }
    if (mapBox.type === 'set') { setMap(mapBox.features); return; }
    if (mapBox.type === 'clean') { cleanUp(); return; }
    cleanUp();
  }, [mapBox]);

  useEffect(() => {
    point?.length > 0 && pushDataLayerForEvent('populate_comments_magic_map');
  }, [point?.length > 0]);

  return <div className={`${interactionState === 'ready' && 'filter blur-lg'}`} style={{ height: mapHeight, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} ref={mapContainerRef} />;
};

export default MapBoxComponent;

import SvgIconBox from '@atoms/SvgIconBox';
import React, { useContext } from 'react';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';

import Wordcloud from '../../public/images/InteractionPage/dynamic-svg/wordcloud.svg';
import PollWithOptions from '../../public/images/InteractionPage/dynamic-svg/poll.svg';
import Map from '../../public/images/InteractionPage/dynamic-svg/map.svg';
import Thoughts from '../../public/images/InteractionPage/dynamic-svg/thoughts.svg';
import Wheel from '../../public/images/InteractionPage/dynamic-svg/wheel.svg';
import Circle from '../../public/images/InteractionPage/dynamic-svg/circle.svg';
import QuickQuestion from '../../public/images/InteractionPage/dynamic-svg/questions.svg';
import Poll from '../../public/images/InteractionPage/dynamic-svg/pollff.svg';
import Slide from '../../public/images/InteractionPage/dynamic-svg/slides.svg';
import LinkLibrary from '../../public/images/InteractionPage/dynamic-svg/link-library.svg';
import TextTrack from '../../public/images/InteractionPage/dynamic-svg/text-track.svg';
import TalkingTiles from '../../public/images/InteractionPage/dynamic-svg/talkingTiles.svg';
import EmojisEverywhereIcon from '../../public/images/InteractionPage/dynamic-svg/emojiEverywhere.svg';
import RatingPollsIcon from '../../public/images/InteractionPage/dynamic-svg/pulsing-points.svg';
import MultipleChoiceIcon from '../../public/images/InteractionPage/dynamic-svg/multiple-choice.svg';

import { TeamsDataContext } from '@components/context/TeamsContext';

import Image from '@atoms/Image';

const interactionsMap = {
  transientThought: {
    color: 'text-interactionIcons-TransientThoughts', text2: ': Type your thoughts out...', text1: 'transient thoughts', icon: <Thoughts width="24" height="24" className="fill-current text-interactionIcons-TransientThoughts" />
  },
  pollWithOptions: {
    color: 'text-interactionIcons-PollWithOptions', text1: 'POWER POLL WITH OPTIONS', text2: ': Type 1 or 2 or 3 ... in the chat', icon: <PollWithOptions width="32" height="32" className="fill-current text-interactionIcons-PollWithOptions" />
  },
  freeForm: {
    color: 'text-interactionIcons-PollWithOutOptions', text1: 'Power Poll Without Options', text2: ': SHOWING TOP 10 RESULTS', icon: <Poll width="24" height="24" className="fill-current text-interactionIcons-PollWithOutOptions" />
  },
  map: {
    color: 'text-interactionIcons-MagicMap', text1: 'Magic Maps', text2: ': TYPE LOCATIONS IN THE CHAT', icon: <Map width="24" height="24" className="fill-current text-interactionIcons-MagicMap" />
  },
  wordCloud: {
    color: 'text-interactionIcons-WonderWords', text1: 'Wonder Words', text2: ': SHOWING TOP 50 RESULTS', icon: <Wordcloud width="24" height="24" className="fill-current text-interactionIcons-WonderWords" />
  },
  'Quick Questions': {
    color: 'text-interactionIcons-QuickQuestions', text1: 'quick questions', text2: '', icon: <QuickQuestion width="24" height="24" className="fill-current text-interactionIcons-QuickQuestions" />
  },
  wheel: {
    color: 'text-interactionIcons-ChoiceCircle', text1: 'Choice Circle', text2: '', icon: <Circle width="24" height="24" className="fill-current text-interactionIcons-ChoiceCircle" />
  },
  newWheel: {
    color: 'text-interactionIcons-WinnersWheel', text1: 'Winning Wheel', text2: '', icon: <Wheel width="24" height="24" className="fill-current text-interactionIcons-WinnersWheel" />
  },
  emojisEverywhere: {
    color: 'text-interactionIcons-EmojisEverywhere', text1: 'Emojis Everywhere', text2: ': SHOWING TOP 50 RESULTS', icon: <EmojisEverywhereIcon width="24" height="24" className="fill-current text-interactionIcons-EmojisEverywhere" />
  },
  'PDF Slide': {
    color: 'text-interactionIcons-PdfSlide', text1: '', text2: '', icon: <Slide width="24" height="24" className="fill-current text-interactionIcons-PdfSlide" />
  },
  linkLibrary: {
    color: 'text-interactionIcons-linkLibrary', text1: 'Link Library', text2: '', icon: <LinkLibrary width="24" height="24" className="fill-current text-interactionIcons-linkLibrary" />
  },
  textTrack: {
    color: 'text-interactionIcons-TextTrack', text1: 'Text Track', text2: '', icon: <TextTrack width="24" height="24" className="fill-current text-interactionIcons-TextTrack" />
  },
  talkingTiles: {
    color: 'text-interactionIcons-TalkingTiles', text1: 'Talking Tiles', text2: ': Type your thoughts out...', icon: <TalkingTiles width="39.29" height="37.97" className="fill-current text-interactionIcons-TalkingTiles" />
  },
  quickQuestions: {
    color: 'text-interactionIcons-QuickQuestions', text1: 'Quick Questions', text2: '', icon: <QuickQuestion width="24" height="24" className="fill-current text-interactionIcons-QuickQuestions" />
  },
  fans: { color: 'text-interactionIcons-QuickQuestions', text1: 'Fantastice Fans', text2: '', icon: <Groups2RoundedIcon width="24" height="24" className="fill-current text-interactionIcons-PollWithOptions" /> },

  ratingPoll: { color: 'text-interactionIcons-RatingPolls', text1: 'Rating Polls', text2: '', icon: <RatingPollsIcon width="32" height="32" className="fill-current text-interactionIcons-RatingPolls" /> },

  multipleChoice: { color: 'text-interactionIcons-MultipleChoice', text1: 'Multiple Choice', text2: '', icon: <MultipleChoiceIcon width="32" height="32" className="fill-current text-interactionIcons-MulipleChoice" /> }
};

const ResultHeader = ({ title, type, showType = true, id = '', createdTime = new Date(), textColor = '#424242', hide = false }) => {
  const {
    color, icon, text1
  } = interactionsMap[type] || {};

  const {
    getBrandLogo
  } = useContext(TeamsDataContext);

  const getQuestionTitle = () => {
    if (showType) {
      if (type === 'Quick Questions') {
        return 'StreamAlive found these questions in the chat...';
      }
      if (type === 'textTrack') {
        return 'Text from the chat...';
      }
      if (type === 'linkLibrary') {
        return 'StreamAlive found these links in the chat...';
      }
    }
    return title;
  };

  return (
    <div className={`relative ${hide ? 'hidden' : ''}`}>
      <div className='w-full h-full flex gap-x-4 justify-start p-4' >

        <div className='title flex flex-col flex-grow w-full justify-center'>
          {showType ? <span className={'font-medium !text-xs leading-4 uppercase'} style={{ color: textColor }}>{`${text1}`}
          </span>
            : null}

          {!showType && getQuestionTitle() === '' ?
            <p id={`${id}-title`} className='text-transparent text-xs'>
              .
            </p> :
            <p id={`${id}-title`} className={`${showType ? '!text-2xl line-clamp-2' : 'text-white text-3xl'}  font-semibold pr-4  leading-7 `} style={{ color: textColor }}>
              {getQuestionTitle()}
            </p>
          }
          <p className='absolute opacity-0 select-none' id={`${id}-createdTime`}>{createdTime.toString()}</p>
        </div>
        {!showType && <Image className="inline-block h-[19px] flex-shrink-0 px-5" src={getBrandLogo('/images/logos/logo_white.svg')} />}
      </div >
    </div>
  );
};

export default ResultHeader;

import { getFeaturesCommentDetails } from '@services/youtube-platform.service';
import { chromaPaletteParser } from '../helper/chromaPaletteParser';
import { colorPalette } from '@lib/constants';
import { getMCQWinners } from '@services/presentation-live';
import { ALL_MCQ_SCORERS_ID } from '@components/AdhocInteractionPopup';

const MAX_LIMIT_FOR_TEXT_IN_SECTORS = 75;
const MAX_LIMIT_FOR_BLACK_LINE = 50;
const reds = ['#f26a60'];
const oranges = ['#ff9d8d'];
const yellows = ['#ffe956'];
const greens = ['#55dbab'];
const blueGreens = ['#b1d878'];
const blues = ['#a9cafb']; // 0 and 3 are switched
const pinks = ['#ff5497'];
const purples = ['#b99ac7'];
const pureBlue = ['#81b1fa'];
const colorsOrder = [reds, oranges, yellows, greens, blueGreens, blues, pinks, purples];

// const colors = Array.from({ length: 1 }, (_, i) => colorsOrder.map((row) => row[i])).flat();
const colors = [...colorPalette];

const truncateText = (radius, text, ctx) => {
  const maxWidth = 0.7 * radius;
  const { width } = ctx.measureText(text);

  if (width < maxWidth) {
    return text;
  }

  const ellipses = '...';
  let truncatedWord = '';
  for (let i = 0; i < text.length; i += 1) {
    const char = text[i];
    if (ctx.measureText(truncatedWord + char + ellipses).width > maxWidth) {
      truncatedWord += ellipses;
      break;
    }
    truncatedWord += char;
  }
  return truncatedWord;
};
const addTextToSector = ({
  ctx, centerX, centerY, startAngle, endAngle, text, canvasSide, radius, optionsLength, isPreview
}) => {
  ctx.moveTo(centerX, centerY);
  ctx.translate(centerX, centerY);
  ctx.rotate((startAngle + endAngle) / 2);
  // ctx.rotate(Math.PI + (startAngle + endAngle) / 2); // Text Circumference to center
  ctx.fillStyle = 'black';
  ctx.textAlign = 'start';
  // ctx.textAlign = 'end'; // Text Circumference to center
  ctx.textBaseline = 'middle';
  let fontSize = canvasSide > 300 ? '180%' : '110%';
  if (isPreview) fontSize = '100%';
  const fontWeight = '500';
  if (optionsLength > 30) fontSize = '140%';
  if (optionsLength > 50) fontSize = '130%';
  if (optionsLength > 60) fontSize = '120%';
  if (optionsLength > 70) fontSize = '100%';

  ctx.font = `${fontWeight} ${fontSize} Inter`;
  const fillText = truncateText(radius, text, ctx);
  // const paddingFromCircumference = radius * 0.05; // Text Circumference to center
  const paddingFromCircumference = radius * 0.1;

  const requiredSpace = ctx.measureText(fillText).width;
  // Text is painted from center to circumference
  const x = (startAngle + endAngle + radius - requiredSpace - paddingFromCircumference);
  // const x = -radius + requiredSpace + paddingFromCircumference; // Text Circumference to center
  ctx.fillText(fillText, x, 0);
  ctx.restore();
};

const getFeatureComments = async ({
  question,
  streamId,
  hiddenData,
  setInteractionState,
  turncateWinningWheelOpt,
  setNoUser,
  setOpts,
  setNewWheelUser
}) => {
  const hiddenIds = hiddenData[question.id] || [];
  let { featureId, featureType } = question.metadata;
  const wheelUser = question.setting.wheelShowUser;

  if (featureId === ALL_MCQ_SCORERS_ID) featureId = undefined;

  let response;
  if (featureType === 'multipleChoice') {
    response = await getMCQWinners(streamId, question.setting.wheelMcqLimit, featureId);
  } else {
    response = await getFeaturesCommentDetails(streamId, featureId, wheelUser);
  }

  if (!response.status) return;

  if (!response.entity.length) {
    setInteractionState('no data');
    setNoUser(true);
    setOpts([]);
    return;
  }

  const newWheelUser = response.entity
    .map((user) => ({ id: user.id, body: turncateWinningWheelOpt(user.user) }));

  const opt = newWheelUser.filter((user) => !hiddenIds.includes(user.id));

  setOpts(opt);
  setNewWheelUser(newWheelUser);
  setNoUser(false);
};

function drawSectors(i, ctx, centerX, centerY, sectorAngle, radius, opts, isResult, chromaColor) {
  if (radius <= 0) return;
  const newColors = chromaPaletteParser().parse(colors, chromaColor);
  const startAngle = (i * sectorAngle);
  const endAngle = ((i + 1) * sectorAngle);
  const fillColor = isResult ? opts[i].color : newColors[i % newColors.length];
  ctx.beginPath();
  ctx.strokeStyle = opts.length <= MAX_LIMIT_FOR_BLACK_LINE ? 'black' : newColors[i % newColors.length];
  ctx.lineWidth = 2;
  ctx.moveTo(centerX, centerY);
  ctx.lineTo(
    centerX + (radius * Math.cos(startAngle)),
    centerY + (radius * Math.sin(startAngle))
  );
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.fillStyle = fillColor;
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.save();
  return { startAngle, endAngle };
}

const drawWheel = (canvas, radius, opts, canvasSide, isResult, chromaColor, isPreview) => {
  const ctx = canvas.getContext('2d');
  const pi = Math.PI;

  if (radius <= 0) {
    radius = 1;
  }

  const sectorAngle = (2 * pi) / opts.length;
  const w = canvas.width;
  const h = canvas.height;
  const centerX = w / 2;
  const centerY = h / 2;

  // Outer Circle
  ctx.arc(centerX, centerY, radius + 10, 0, 2 * pi);
  ctx.fillStyle = 'white';
  ctx.fill();

  const addTextToSectors = opts.length <= MAX_LIMIT_FOR_TEXT_IN_SECTORS;
  for (let i = 0; i < opts.length; i += 1) { // Draw the wheel
    const { startAngle, endAngle } = drawSectors(
      i,
      ctx,
      centerX,
      centerY,
      sectorAngle,
      radius,
      opts,
      isResult,
      chromaColor
    );
    if (addTextToSectors) {
      addTextToSector({
        ctx,
        centerX,
        centerY,
        startAngle,
        endAngle,
        radius,
        canvasSide,
        text: opts[i].body,
        optionsLength: opts.length,
        isPreview
      });
    }
  }
  if (opts.length === 0) {
  // inner Circle
    ctx.beginPath();
    ctx.lineWidth = 10;
    ctx.fillStyle = '#DBDBDB';
    ctx.arc(centerX, centerY, parseInt(centerY) - 10, 0, 2 * pi);
    ctx.fill();
    ctx.closePath();
    // inner Circle Border
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';
    ctx.arc(centerX, centerY, parseInt(centerY) - 10, 0, 2 * pi);
    ctx.stroke();
    ctx.closePath();
  }
  // central Circle
  ctx.beginPath();
  ctx.lineWidth = 10;
  ctx.fillStyle = 'white';
  ctx.arc(centerX, centerY, 14, 0, 2 * pi);
  ctx.fill();
  ctx.closePath();
  // central Circle Border
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = 'black';
  ctx.arc(centerX, centerY, 14, 0, 2 * pi);
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = 'black';
  ctx.arc(centerX, centerY, parseInt(centerY - 1), 0, 2 * pi);
  ctx.stroke();
  ctx.closePath();

  // if (isWinnerInfo) {
  //   spinToWinner();
  // }
};

export {
  truncateText, addTextToSector, drawWheel, colors, getFeatureComments
};

import React, { useEffect, useRef, useState } from 'react';
import { getCorrectedFilter, maskFilter } from '@lib/link-library-helper';
import { Modal, ModalBody, ModalContent, ModalHeader } from '@atoms/new/modal';
import Button from '@atoms/new/button';
import { toast } from 'react-toastify';
import { CopyAll, Check } from '@mui/icons-material';
import Input from '@atoms/new/input';

const ShareModel = ({
  selectedFilter, setShowShareModel, streamId, streamName
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const timeoutRef = useRef(null);

  const maskedFilter = maskFilter(getCorrectedFilter(selectedFilter).replace(/ +/g, ''));

  const handleClose = () => setShowShareModel(false);

  const onCopy = () => {
    navigator.clipboard.writeText(`${process.env.NEXT_PUBLIC_URL}streams/${streamId}/linkLibrary?skip=0&filter=${maskedFilter}`);
    setIsCopied(true);
    toast.success('Link copied to clipboard');

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <Modal className="dark:bg-grey-900" size="sm" isOpen={true} onClose={handleClose}>
      <ModalContent>
        <ModalHeader className='dark:text-grey-200'>{`Share - ${selectedFilter} Link Library`}</ModalHeader>
        <ModalBody className="flex flex-col items-center mt-4">
          <div className="w-full">
            <Input fullWidth value={`${process.env.NEXT_PUBLIC_URL}streams/${streamId}/linkLibrary?skip=0&filter=${maskedFilter}`} readOnly className='mb-4' />

            <Button
              kind="primary"
              onClick={onCopy}
              className="w-full"
              startContent={isCopied ? <Check className="text-white" /> : <CopyAll className="text-white" />}
            >
              {isCopied ? 'Copied' : 'Copy link'}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModel;

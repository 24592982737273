import React, { useEffect, useRef, useState } from 'react';
import { getRandomColor } from './helper';
import Avatar from '@atoms/Avatar';

const darkenColor = (color, percent) => {
  if (!color) return color;
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const r = (num >> 16) - amt;
  const g = ((num >> 8) & 0x00ff) - amt;
  const b = (num & 0x0000ff) - amt;
  return `rgb(${Math.max(r, 0)}, ${Math.max(g, 0)}, ${Math.max(b, 0)})`;
};

const ResultTile = ({ tiles, currentRosFeature, isResultTileLoading = false, analytics }) => {
  const scrollRef = useRef(null);
  const [columns, setColumns] = useState(Array.from({ length: 3 }, () => []));
  const TILE_GAP = 30;

  const colors = currentRosFeature?.colors || currentRosFeature?.color;

  useEffect(() => {
    const result = tiles.filter((tile) => tile.words || tile.body).map((tile) => {
      return {
        ...tile,
        fontColor: colors?.talkingTilesTextColor || '#111111',
        backgroundColor: getRandomColor(currentRosFeature)
      };
    });

    const columns = [[], [], []];
    result.forEach((tile, index) => {
      columns[index % 3].push(tile);
    });

    setColumns(columns);
  }, [tiles, currentRosFeature]);

  const settings = currentRosFeature?.settings || currentRosFeature?.setting;

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [columns, tiles, scrollRef.current]);

  if (tiles.length === 0 && !isResultTileLoading) {
    return <div className='flex justify-center items-center text-center text-2xl font-Inter'>No comments to show</div>;
  }

  return (
    <div className={`${analytics ? 'h-full' : 'h-[820px]'} flex flex-col  `}>
      <div ref={scrollRef} className="flex-1 overflow-y-auto p-4  scrollbar-hide" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <div className="grid grid-cols-3 gap-4  min-h-full">
          {columns.map((column, columnIndex) => (
            <div key={columnIndex} className="flex-1 flex flex-col justify-end gap-8 pb-4">
              {column.map((tile) => (
                <TileContent
                  key={tile.id}
                  tile={tile}
                  fontFamily={settings?.FontStyle || 'inter'}
                  showUsername={settings?.showUserName}
                  tileGap={TILE_GAP}
                  fontColor={tile.fontColor}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TileContent = ({ tile, fontFamily, showUsername, fontColor }) => {
  const word = tile?.words || tile?.body;
  const truncatedText = word?.length >= 255 ? `${word.substring(0, 255)}...` : word;
  const maxUsernameLength = 30;
  const truncatedUsername = tile?.username?.length > maxUsernameLength ?
    `${tile?.username?.substring(0, maxUsernameLength)}...` :
    tile?.username;

  return (
    <div
      style={{
        backgroundColor: tile.backgroundColor,
        padding: '0px 24px 24px 24px',
        borderRadius: '16px',
        boxShadow: `0px 10px 0px ${darkenColor(tile.backgroundColor, 20)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        minHeight: '180px',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start',
          overflow: 'hidden',
          paddingTop: '24px',
          paddingBottom: '24px',

        }}
      >
        <p
          style={{
            color: fontColor || '#000',
            margin: 0,
            lineHeight: '1.3',
            fontSize: '24px',
            wordWrap: 'break-word',

            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            fontFamily,
            textAlign: 'start',
            width: '100%',
          }}
        >
          {truncatedText}
        </p>
      </div>
      {tile.username && showUsername && (
        <div className='flex items-center gap-2'>
          <Avatar isProfile staticColor='bg-grey-500' name={truncatedUsername} />
          <p className={`text-grey-900 text-sm font-${fontFamily}`} style={{ color: fontColor }}>
            {truncatedUsername}
          </p>
        </div>
      )}
    </div>
  );
};

export default ResultTile;

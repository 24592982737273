import { useEffect, useRef, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import useRatingPollsCanvas from './hooks/useRatingPollsCanvas';
import { stopEngagement } from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { logToCloudWatch } from '@services/logger.service';
import Container from '@atoms/Container';
import Image from 'next/image';
import { handleEngagement } from '@services/interactions-service';
import ResultHeader from '@components/analytics/ResultHeader';

const defaultRatingPollsOptions = Array.from({ length: 5 }, (_, i) => ({
  id: uuidv4(),
  body: ` ${i + 1}`,
  option_number: i + 1,
  count: 0,

}));

const initialRating = {
  averageRating: '0.0',
  contribution: defaultRatingPollsOptions.map((option) => ({
    option_number: option.option_number,
    percentage: 0,
    count: 0,
    body: option.body
  }))
};

const RatingPollsResult = (props: any) => {
  const { analyticsData: { data, id, liveStreamId, setting, color, title, type } } = props;
  const feature = {
    id,
    setting,
    settings: {
      ...setting
    },
    color
  };

  const { containerRef } = useRatingPollsCanvas(data, feature, liveStreamId, `ratingPollsAnalytics-${id}`, true);
  const bgImg = setting?.ratingPollsImageSrc;
  const bgImgOpacity = setting?.ratingPollsImageOpacity / 100;
  return (
    <Container
      style={{ background: color?.ratingPollsBgColor }}
      className="bg-neutral-80 !p-3 aspect-video flex flex-col rounded-[16px] justify-center item-center relative overflow-hidden">
      {bgImg && <Image
        style={{ opacity: bgImgOpacity }}
        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImg}`}
        className=' object-cover rounded-lg'
        fill
        alt='background image'
      />}
      <ResultHeader title={title} type={type} textColor={color.ratingPollsHeadingColor} />
      <div ref={containerRef} className='ratingPollWrapper w-full h-full' >
        <canvas id={`ratingPollsAnalytics-${id}`} />
      </div>
    </Container>
  );
};

const RatingPolls = ({
  streamId, currentRosResult, currentRosFeature, colorData, streamSettings, platformType,
  startInteraction, setCurrentFeatureId, interactionType, isImpromptu,
  setShowRosModel, activeInteractionRef, updateFSMeta, setShowNotification,
  isAnalytics = false, analyticsData = {}, setStartTime, showInstantCustomisationPopup
}: any) => {
  if (isAnalytics) return <RatingPollsResult analyticsData={analyticsData} />;
  const {
    firestoreUnsub,
    setFirestoreUnsub,
    setFeatureId
  } = useContext(StreamDataContext);

  const { isRos, isResult } = interactionType;
  const [ratingPollsId, setRatingPollsId] = useState(null);
  const [rating, setRating] = useState(initialRating);
  const totalCommentsRef: any = useRef([]);
  const [, setFeatureIdUpdated] = useState(false);
  const [previousData, setPreviousData] = useState<any>(null);

  const ratingPoll = previousData ? {
    data: previousData,
    ...previousData
  } : {
    data: rating,
    ...currentRosFeature
  };
  const { containerRef } = useRatingPollsCanvas(ratingPoll.data, ratingPoll, streamId, 'ratingPollCanvas', false, showInstantCustomisationPopup);

  const setupRatingPolls = ({ id, current, prevData }: any) => {
    totalCommentsRef.current = current;
    setRating(prevData);
    setRatingPollsId(id);
    setFeatureId(id);
    setCurrentFeatureId(id);
    setPreviousData(null);
  };

  const handleRatingPolls = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        type: currentRosFeature.type,
        prevData: initialRating
      };
      setupRatingPolls(interactionDetails);
      updateFSMeta({ activeInteraction: interactionDetails });
    }
  };

  useEffect(() => {
    if (currentRosFeature.id) {
      setPreviousData(currentRosResult.length && currentRosResult[0] ? currentRosResult[0] : currentRosFeature);
    }
  }, [currentRosFeature.id, currentRosResult]);

  useEffect(() => {
    if (activeInteractionRef.current && activeInteractionRef.current?.type === 'ratingPolls') {
      setupRatingPolls({
        id: activeInteractionRef.current.id,
        current: [],
        counter: 0,
        prevData: initialRating
      });
    }
  }, [activeInteractionRef.current]);

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const stopRatingPolls = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'ratingPolls', { engagementId: ratingPollsId, platformType, sessionId });
  };

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleRatingPolls({ interactionId: currentRosResult[0].id });
    } else {
      handleRatingPolls({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopRatingPolls();
    setRatingPollsId(null);
  };

  const manageRatingPollsInteraction = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (ratingPollsId) {
      handleStopInteraction();
    }
  };

  useEffect(() => {
    if (!currentRosFeature.id) return;

    setShowRosModel(false);
    manageRatingPollsInteraction();
  }, [currentRosFeature.id, startInteraction]);

  useEffect(() => {
    setFeatureIdUpdated(true);
    if (ratingPollsId) {
      const logData = {
        streamId,
        interactionType: 'ratingPolls',
        interactionId: ratingPollsId
      };
      setFirestoreUnsub({
        unSub: onSnapshot(doc(db, 'streams', streamId, 'ratingPolls', ratingPollsId), (document: any) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            setFeatureIdUpdated(false);
            const { data: rating } = document.data();
            if (rating) {
              setShowNotification(false);
              setRating({ averageRating: rating?.averageRating || 0.0, contribution: rating?.contribution });
            }
          }
        }, (error: any) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [ratingPollsId]);

  return (
    <div ref={containerRef} className='ratingPollWrapper w-full h-full ' >
      <canvas id='ratingPollCanvas' />
    </div>
  );
};

export default RatingPolls;
